body.CherryRed *{
    color: #e96363 !important;
    font-size: 12px;
}

body.CherryRed .box{
    overflow: hidden;
    border-radius: 5px;
}

body.CherryRed .box-header{
    background-color: #ffd3d3;
    color: #e96363;
}

body.CherryRed .landing{
    background-color: #fff6f6 !important;
}

body.CherryRed .iconLogo i{
    color: #e96363;
}

body.CherryRed .primary{
    color: #e96363 !important;
}

body.CherryRed .nav li.active{
    border-bottom: solid 2px #e96363;
}

body.CherryRed .react-responsive-modal-closeButton {
    background-color: #e96363;
}

body.CherryRed .btn-search {
    background: linear-gradient(180deg, #ffd3d3 0%, #ffd3d3 100%);
}

body.CherryRed .pagination ul li.active{
    background: #ffd3d3;
}

body.CherryRed table tr th{
    background: #ffd3d3;
}

body.CherryRed .iconLogo .box {
    background: #fff6f6;
}

body.CherryRed .box{
    border: solid 1px #ffd3d3;
}

body.CherryRed input, body.CherryRed textarea, body.CherryRed select{
    border: solid #ffd3d3 1px;
    background-color:#ffd3d3 !important;
}

body.CherryRed input:-internal-autofill-selected {
    background-color:#ffd3d3 !important;
}    


body.Beggars *{
    color: #686868 !important;
    font-family: 'Times New Roman', Times, serif;
}

body.Beggars .box{
    overflow: hidden;
    border-radius: 10px;
}

body.Beggars .box-header{
    background-color: #e7e7e7;
    color: #686868;
}

body.Beggars .landing{
    background-color: #f5f5f5 !important;
}

body.Beggars .iconLogo i{
    color: #686868;
}

body.Beggars .primary{
    color: #686868 !important;
}

body.Beggars .nav li.active{
    border-bottom: solid 2px #686868;
}

body.Beggars .react-responsive-modal-closeButton {
    background-color: #686868;
}

body.Beggars .btn-search {
    background: linear-gradient(180deg, #e7e7e7 0%, #e7e7e7 100%);
}

body.Beggars .pagination ul li.active{
    background: #e7e7e7;
}

body.Beggars table tr th{
    background: #e7e7e7;
}

body.Beggars .iconLogo .box {
    background: #f5f5f5;
}

body.Beggars .box{
    border: solid 1px #e7e7e7;
}

body.Beggars input, body.Beggars textarea, body.Beggars select{
    border: solid #e7e7e7 1px;
    background-color:#e7e7e7 !important;
}

body.Beggars input:-internal-autofill-selected {
    background-color:#e7e7e7 !important;
}  


