@font-face {
  font-family: 'icomoon';
  src:
    url('fonts/icomoon.ttf?t3pj7g') format('truetype'),
    url('fonts/icomoon.woff?t3pj7g') format('woff'),
    url('fonts/icomoon.svg?t3pj7g#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sub-contact .path1:before {
  content: "\eab5";
  color: rgb(101, 106, 163);
}
.icon-sub-contact .path2:before {
  content: "\eab6";
  margin-left: -1.14453125em;
  color: rgb(223, 0, 0);
}
.icon-sub-contract .path1:before {
  content: "\eab7";
  color: rgb(101, 106, 163);
}
.icon-sub-contract .path2:before {
  content: "\eab8";
  margin-left: -0.9111328125em;
  color: rgb(223, 0, 0);
}
.icon-add-contact .path1:before {
  content: "\eab1";
  color: rgb(101, 106, 163);
}
.icon-add-contact .path2:before {
  content: "\eab2";
  margin-left: -1.095703125em;
  color: rgb(39, 174, 96);
}
.icon-add-contract .path1:before {
  content: "\eab3";
  color: rgb(101, 106, 163);
}
.icon-add-contract .path2:before {
  content: "\eab4";
  margin-left: -0.826171875em;
  color: rgb(39, 174, 96);
}
.icon-Assets:before {
  content: "\e910";
}
.icon-bank:before {
  content: "\ea9a";
}
.icon-LinkBroken:before {
  content: "\ea98";
}
.icon-LinkMade:before {
  content: "\ea99";
}
.icon-apple:before {
  content: "\ea95";
  color: #999;
}
.icon-spotify:before {
  content: "\ea96";
  color: #1ed760;
}
.icon-youtube:before {
  content: "\ea97";
  color: #f00;
}
.icon-Licensing:before {
  content: "\ea90";
}
.icon-Pitch:before {
  content: "\ea91";
}
.icon-Grid_ProjectContract:before {
  content: "\ea92";
}
.icon-DataManagement:before {
  content: "\ea93";
}
.icon-SuggestedPayments:before {
  content: "\ea94";
}
.icon-SongRegistration:before {
  content: "\ea8c";
}
.icon-Transaction:before {
  content: "\ea8d";
}
.icon-TrackSplit:before {
  content: "\ea8e";
}
.icon-Matching:before {
  content: "\ea8f";
}
.icon-calculator:before {
  content: "\ea7d";
}
.icon-calculator-money:before {
  content: "\ea7e";
}
.icon-calculate-math:before {
  content: "\ea81";
}
.icon-sigma-sum:before {
  content: "\ea82";
}
.icon-total:before {
  content: "\ea83";
}
.icon-price-list:before {
  content: "\ea84";
}
.icon-new-registration:before {
  content: "\ea85";
}
.icon-add-file:before {
  content: "\ea86";
}
.icon-join:before {
  content: "\ea87";
}
.icon-generate-report:before {
  content: "\ea88";
}
.icon-data-analytics:before {
  content: "\ea89";
}
.icon-accounting:before {
  content: "\ea8a";
}
.icon-alpha:before {
  content: "\ea8b";
}
.icon-Affiliate:before {
  content: "\ea12";
}
.icon-add3:before {
  content: "\ea76";
}
.icon-arrow-11:before {
  content: "\ea77";
}
.icon-arrow-long1:before {
  content: "\ea78";
}
.icon-calendar6:before {
  content: "\ea79";
}
.icon-close3:before {
  content: "\ea7a";
}
.icon-edit2:before {
  content: "\ea7b";
}
.icon-play-with-c:before {
  content: "\ea7c";
}
.icon-tick1:before {
  content: "\ea7f";
}
.icon-Grid_Reporting:before {
  content: "\ea6e";
}
.icon-Grid_Contracts:before {
  content: "\ea6f";
}
.icon-Grid_Contacts:before {
  content: "\ea70";
}
.icon-Grid_Approval:before {
  content: "\ea71";
}
.icon-zip-doc:before {
  content: "\e911";
}
.icon-xls:before {
  content: "\e912";
}
.icon-waltDisney:before {
  content: "\e913";
}
.icon-vertical-line:before {
  content: "\e914";
}
.icon-user2:before {
  content: "\e915";
}
.icon-Union-31:before {
  content: "\e916";
  color: #626fbc;
}
.icon-track:before {
  content: "\e917";
}
.icon-tick:before {
  content: "\e918";
}
.icon-Synch_Project:before {
  content: "\e919";
}
.icon-song:before {
  content: "\e91a";
}
.icon-reporting:before {
  content: "\e91c";
}
.icon-release:before {
  content: "\e9e5";
}
.icon-Recorded_Music:before {
  content: "\e9e6";
}
.icon-Recorded-music:before {
  content: "\e9e7";
}
.icon-recorded-music-publ:before {
  content: "\e9e8";
}
.icon-recorded-music-mech:before {
  content: "\e9e9";
}
.icon-radio:before {
  content: "\e9ea";
  color: #626fbc;
}
.icon-Publishing_Transaction:before {
  content: "\e9eb";
}
.icon-Publishing_Project:before {
  content: "\e9ec";
}
.icon-publishing-trans:before {
  content: "\e9ed";
}
.icon-projecto-mechn:before {
  content: "\e9ee";
}
.icon-project:before {
  content: "\e9ef";
}
.icon-project-sync:before {
  content: "\e9f0";
}
.icon-project-publ:before {
  content: "\e9f1";
}
.icon-pdf .path1:before {
  content: "\e9f2";
  color: rgb(230, 45, 45);
}
.icon-pdf .path2:before {
  content: "\e9f3";
  margin-left: -0.8681640625em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path3:before {
  content: "\e9f4";
  margin-left: -0.8681640625em;
  color: rgb(238, 153, 153);
}
.icon-pdf .path4:before {
  content: "\e9f5";
  margin-left: -0.8681640625em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path5:before {
  content: "\e9f6";
  margin-left: -0.8681640625em;
  color: rgb(230, 45, 45);
}
.icon-pdf .path6:before {
  content: "\e9f7";
  margin-left: -0.8681640625em;
  color: rgb(230, 45, 45);
}
.icon-pdf .path7:before {
  content: "\e9f8";
  margin-left: -0.8681640625em;
  color: rgb(230, 45, 45);
}
.icon-pdf .path8:before {
  content: "\e9f9";
  margin-left: -0.8681640625em;
  color: rgb(202, 209, 216);
}
.icon-pdf-doc:before {
  content: "\e9fa";
}
.icon-Other_Transaction:before {
  content: "\e9fb";
}
.icon-other-trans:before {
  content: "\e9fc";
}
.icon-notification:before {
  content: "\e9fd";
}
.icon-music4:before {
  content: "\e9fe";
  color: #626fbc;
}
.icon-mickey:before {
  content: "\e9ff";
}
.icon-menu2:before {
  content: "\ea00";
}
.icon-menu-icon:before {
  content: "\ea01";
  color: #626fbc;
}
.icon-Mechanical_Project:before {
  content: "\ea02";
}
.icon-LR:before {
  content: "\ea03";
}
.icon-LPurple-Setting:before {
  content: "\ea04";
}
.icon-LP:before {
  content: "\ea05";
}
.icon-LM:before {
  content: "\ea06";
}
.icon-link1:before {
  content: "\ea07";
}
.icon-LGreen-O:before {
  content: "\ea08";
}
.icon-LGreen-D:before {
  content: "\ea09";
}
.icon-LGreen-B:before {
  content: "\ea0a";
}
.icon-LCylinder-T:before {
  content: "\ea0b";
}
.icon-LCylinder-S:before {
  content: "\ea0c";
}
.icon-LCylinder-R:before {
  content: "\ea0d";
}
.icon-info1:before {
  content: "\ea0e";
}
.icon-info-icon .path1:before {
  content: "\ea0f";
  color: rgb(255, 255, 255);
}
.icon-info-icon .path2:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(70, 85, 180);
}
.icon-info-icon .path3:before {
  content: "\ea11";
  margin-left: -1em;
  color: rgb(70, 85, 180);
}
.icon-horizontal-line:before {
  content: "\ea13";
}
.icon-home1:before {
  content: "\ea14";
}
.icon-globe1:before {
  content: "\ea15";
}
.icon-global:before {
  content: "\ea16";
  color: #626fbc;
}
.icon-folder2:before {
  content: "\ea17";
  color: #626fbc;
}
.icon-folder-sync:before {
  content: "\ea18";
  color: #626fbc;
}
.icon-folder-publishing:before {
  content: "\ea19";
  color: #626fbc;
}
.icon-folder-mechanical:before {
  content: "\ea1a";
  color: #626fbc;
}
.icon-filled-circle:before {
  content: "\ea1b";
}
.icon-edit1:before {
  content: "\ea1c";
}
.icon-doc-file:before {
  content: "\ea1d";
}
.icon-ditital-trans-publishing:before {
  content: "\ea1e";
  color: #626fbc;
}
.icon-disney:before {
  content: "\ea1f";
}
.icon-Digital_Transaction:before {
  content: "\ea20";
}
.icon-digital-trans:before {
  content: "\ea21";
}
.icon-digital-trans-other:before {
  content: "\ea22";
  color: #626fbc;
}
.icon-customer:before {
  content: "\ea23";
  color: #626fbc;
}
.icon-cross .path1:before {
  content: "\ea24";
  color: rgb(53, 46, 46);
}
.icon-cross .path2:before {
  content: "\ea25";
  margin-left: -0.9423828125em;
  color: rgb(145, 145, 145);
}
.icon-cross .path3:before {
  content: "\ea26";
  margin-left: -0.9423828125em;
  color: rgb(145, 145, 145);
}
.icon-contracts:before {
  content: "\ea27";
}
.icon-contract1:before {
  content: "\ea28";
  color: #626fbc;
}
.icon-contract-publishing:before {
  content: "\ea29";
  color: #626fbc;
}
.icon-contract-mechanical:before {
  content: "\ea2a";
  color: #626fbc;
}
.icon-contacts:before {
  content: "\ea2b";
}
.icon-close1:before {
  content: "\ea2c";
}
.icon-calendar5:before {
  content: "\ea2d";
}
.icon-bar-chart2:before {
  content: "\ea2e";
  color: #626fbc;
}
.icon-arrows:before {
  content: "\ea2f";
}
.icon-arrow-point-to-right-2:before {
  content: "\ea30";
  color: #626fbc;
}
.icon-arrow-long:before {
  content: "\ea31";
}
.icon-arrow-1:before {
  content: "\ea32";
}
.icon-approval:before {
  content: "\ea33";
}
.icon-add1:before {
  content: "\ea34";
}
.icon-Mechanical:before {
  content: "\ea35";
  color: #777d9e;
}
.icon-approval1:before {
  content: "\ea36";
  color: #777d9e;
}
.icon-zip2 .path1:before {
  content: "\ea37";
  color: rgb(119, 125, 158);
}
.icon-zip2 .path2:before {
  content: "\ea38";
  margin-left: -0.875em;
  color: rgb(255, 255, 255);
}
.icon-zip2 .path3:before {
  content: "\ea39";
  margin-left: -0.875em;
  color: rgb(213, 215, 229);
}
.icon-zip2 .path4:before {
  content: "\ea3a";
  margin-left: -0.875em;
  color: rgb(255, 255, 255);
}
.icon-zip2 .path5:before {
  content: "\ea3b";
  margin-left: -0.875em;
  color: rgb(119, 125, 158);
}
.icon-zip2 .path6:before {
  content: "\ea3c";
  margin-left: -0.875em;
  color: rgb(119, 125, 158);
}
.icon-zip2 .path7:before {
  content: "\ea3d";
  margin-left: -0.875em;
  color: rgb(119, 125, 158);
}
.icon-zip2 .path8:before {
  content: "\ea3e";
  margin-left: -0.875em;
  color: rgb(202, 209, 216);
}
.icon-xls1 .path1:before {
  content: "\ea3f";
  color: rgb(119, 125, 158);
}
.icon-xls1 .path2:before {
  content: "\ea40";
  margin-left: -0.875em;
  color: rgb(255, 255, 255);
}
.icon-xls1 .path3:before {
  content: "\ea41";
  margin-left: -0.875em;
  color: rgb(213, 215, 229);
}
.icon-xls1 .path4:before {
  content: "\ea42";
  margin-left: -0.875em;
  color: rgb(255, 255, 255);
}
.icon-xls1 .path5:before {
  content: "\ea43";
  margin-left: -0.875em;
  color: rgb(119, 125, 158);
}
.icon-xls1 .path6:before {
  content: "\ea44";
  margin-left: -0.875em;
  color: rgb(119, 125, 158);
}
.icon-xls1 .path7:before {
  content: "\ea45";
  margin-left: -0.875em;
  color: rgb(119, 125, 158);
}
.icon-xls1 .path8:before {
  content: "\ea46";
  margin-left: -0.875em;
  color: rgb(202, 209, 216);
}
.icon-vertical-line1:before {
  content: "\ea47";
  color: #777d9e;
}
.icon-Track:before {
  content: "\ea48";
  color: #777d9e;
}
.icon-tog-off:before {
  content: "\ea49";
  color: #777d9e;
}
.icon-Sync-project:before {
  content: "\ea4a";
  color: #777d9e;
}
.icon-Song:before {
  content: "\ea4b";
  color: #777d9e;
}
.icon-reporting1:before {
  content: "\ea4c";
  color: #777d9e;
}
.icon-Release:before {
  content: "\ea4d";
  color: #777d9e;
}
.icon-Recorded-music1:before {
  content: "\ea4e";
  color: #777d9e;
}
.icon-Publishing:before {
  content: "\ea4f";
  color: #777d9e;
}
.icon-Publishing-trans:before {
  content: "\ea50";
  color: #777d9e;
}
.icon-Publishing-project:before {
  content: "\ea51";
  color: #777d9e;
}
.icon-Project:before {
  content: "\ea52";
  color: #777d9e;
}
.icon-pdf1 .path1:before {
  content: "\ea53";
  color: rgb(119, 125, 158);
}
.icon-pdf1 .path2:before {
  content: "\ea54";
  margin-left: -0.8681640625em;
  color: rgb(255, 255, 255);
}
.icon-pdf1 .path3:before {
  content: "\ea55";
  margin-left: -0.8681640625em;
  color: rgb(213, 215, 229);
}
.icon-pdf1 .path4:before {
  content: "\ea56";
  margin-left: -0.8681640625em;
  color: rgb(255, 255, 255);
}
.icon-pdf1 .path5:before {
  content: "\ea57";
  margin-left: -0.8681640625em;
  color: rgb(119, 125, 158);
}
.icon-pdf1 .path6:before {
  content: "\ea58";
  margin-left: -0.8681640625em;
  color: rgb(119, 125, 158);
}
.icon-pdf1 .path7:before {
  content: "\ea59";
  margin-left: -0.8681640625em;
  color: rgb(119, 125, 158);
}
.icon-pdf1 .path8:before {
  content: "\ea5a";
  margin-left: -0.8681640625em;
  color: rgb(202, 209, 216);
}
.icon-Other-trans:before {
  content: "\ea5b";
  color: #777d9e;
}
.icon-notification1:before {
  content: "\ea5c";
  color: #777d9e;
}
.icon-menu3:before {
  content: "\ea5e";
  color: #777d9e;
}
.icon-Mechanical-project:before {
  content: "\ea5f";
  color: #777d9e;
}
.icon-link2:before {
  content: "\ea60";
  color: #777d9e;
}
.icon-info-icon-with-white-bg:before {
  content: "\ea61";
  color: #777d9e;
}
.icon-globe2:before {
  content: "\ea62";
  color: #777d9e;
}
.icon-Digital-trans:before {
  content: "\ea63";
  color: #777d9e;
}
.icon-contracts1:before {
  content: "\ea64";
  color: #777d9e;
}
.icon-contacts1:before {
  content: "\ea65";
  color: #777d9e;
}
.icon-close2:before {
  content: "\ea66";
  color: #777d9e;
}
.icon-arrow:before {
  content: "\ea67";
  color: #777d9e;
}
.icon-add2:before {
  content: "\ea68";
  color: #777d9e;
}
.icon-tog-on:before {
  content: "\ea69";
  color: #777d9e;
}
.icon-long-arrow:before {
  content: "\ea6b";
  color: #777d9e;
}
.icon-horizontal-line1:before {
  content: "\ea6c";
  color: #777d9e;
}
.icon-dbl-arrows:before {
  content: "\ea6d";
  color: #777d9e;
}
.icon-user1:before {
  content: "\ea5d";
}
.icon-cd:before {
  content: "\e97e";
}
.icon-floppy:before {
  content: "\e97f";
}
.icon-camera:before {
  content: "\e980";
}
.icon-volume:before {
  content: "\e981";
}
.icon-music2:before {
  content: "\e984";
}
.icon-ipod:before {
  content: "\e985";
}
.icon-headphone:before {
  content: "\e986";
}
.icon-cassette:before {
  content: "\e987";
}
.icon-broadcast:before {
  content: "\e989";
}
.icon-broadcast1:before {
  content: "\e98a";
}
.icon-calculator2:before {
  content: "\e98f";
}
.icon-cog:before {
  content: "\e996";
}
.icon-earth1:before {
  content: "\e997";
}
.icon-folder1:before {
  content: "\e998";
}
.icon-search:before {
  content: "\e999";
}
.icon-binocular:before {
  content: "\e9b9";
}
.icon-tag:before {
  content: "\e9ba";
}
.icon-attachment:before {
  content: "\e9bb";
}
.icon-trashcan:before {
  content: "\e9bc";
}
.icon-cart:before {
  content: "\e9be";
}
.icon-bag:before {
  content: "\e9bf";
}
.icon-suitcase:before {
  content: "\e9c0";
}
.icon-card:before {
  content: "\e9c1";
}
.icon-book:before {
  content: "\e9c2";
}
.icon-lamp2:before {
  content: "\e9c3";
}
.icon-settings1:before {
  content: "\e9c4";
}
.icon-locked1:before {
  content: "\e9c5";
}
.icon-unlocked1:before {
  content: "\e9c6";
}
.icon-key:before {
  content: "\e9c7";
}
.icon-info:before {
  content: "\e9c8";
}
.icon-clock:before {
  content: "\e9c9";
}
.icon-star1:before {
  content: "\e9cc";
}
.icon-refresh:before {
  content: "\e9cd";
}
.icon-arrow-up:before {
  content: "\e90a";
}
.icon-arrow-down:before {
  content: "\e90b";
}
.icon-arrow-left:before {
  content: "\e920";
}
.icon-arrow-right:before {
  content: "\e921";
}
.icon-arrow-top-right:before {
  content: "\e922";
}
.icon-arrow-top-left:before {
  content: "\e923";
}
.icon-arrow-bottom-right:before {
  content: "\e924";
}
.icon-arrow-bottom-left:before {
  content: "\e925";
}
.icon-contract:before {
  content: "\e926";
}
.icon-star2:before {
  content: "\e9d7";
}
.icon-sound:before {
  content: "\e9d8";
}
.icon-trash:before {
  content: "\e9d9";
}
.icon-settings2:before {
  content: "\e9da";
}
.icon-mail:before {
  content: "\e9db";
}
.icon-photo1:before {
  content: "\e9dc";
}
.icon-note:before {
  content: "\e9dd";
}
.icon-params1:before {
  content: "\e9de";
}
.icon-data1:before {
  content: "\e9df";
}
.icon-music3:before {
  content: "\e9e0";
}
.icon-calendar4:before {
  content: "\e9e1";
}
.icon-wallet:before {
  content: "\e9e2";
}
.icon-vynil:before {
  content: "\e9e3";
}
.icon-world:before {
  content: "\e9e4";
}
.icon-megaphone:before {
  content: "\e021";
}
.icon-star:before {
  content: "\e988";
}
.icon-user:before {
  content: "\e91d";
}
.icon-settings:before {
  content: "\e98b";
}
.icon-camera1:before {
  content: "\e98c";
}
.icon-tag2:before {
  content: "\e903";
}
.icon-bulb:before {
  content: "\e98d";
}
.icon-pen:before {
  content: "\e905";
}
.icon-eye:before {
  content: "\e98e";
}
.icon-params:before {
  content: "\e990";
}
.icon-banknote:before {
  content: "\e991";
}
.icon-data:before {
  content: "\e992";
}
.icon-music:before {
  content: "\e993";
}
.icon-fire:before {
  content: "\e929";
}
.icon-calendar:before {
  content: "\e99a";
}
.icon-calendar1:before {
  content: "\e92a";
}
.icon-file:before {
  content: "\e92b";
}
.icon-file1:before {
  content: "\e92c";
}
.icon-file2:before {
  content: "\e92d";
}
.icon-files:before {
  content: "\e92e";
}
.icon-checkmark:before {
  content: "\e99b";
}
.icon-checkmark1:before {
  content: "\e99c";
}
.icon-cancel:before {
  content: "\e99d";
}
.icon-cancel1:before {
  content: "\e99e";
}
.icon-plus:before {
  content: "\e99f";
}
.icon-plus1:before {
  content: "\e9a0";
}
.icon-minus:before {
  content: "\e9a1";
}
.icon-minus1:before {
  content: "\e9a2";
}
.icon-notice:before {
  content: "\e9a3";
}
.icon-notice1:before {
  content: "\e9a4";
}
.icon-warning:before {
  content: "\e9a5";
}
.icon-picture:before {
  content: "\e92f";
}
.icon-pictures:before {
  content: "\e930";
}
.icon-pictures1:before {
  content: "\e931";
}
.icon-headphones:before {
  content: "\e9a6";
}
.icon-checkmark2:before {
  content: "\e9a7";
}
.icon-cancel2:before {
  content: "\e9a8";
}
.icon-cloud:before {
  content: "\e932";
}
.icon-upload:before {
  content: "\e933";
}
.icon-chart:before {
  content: "\e9a9";
}
.icon-chart1:before {
  content: "\e9aa";
}
.icon-chart2:before {
  content: "\e9ab";
}
.icon-chart5:before {
  content: "\e934";
}
.icon-chart6:before {
  content: "\e935";
}
.icon-chart3:before {
  content: "\e90c";
}
.icon-download2:before {
  content: "\e937";
}
.icon-folder:before {
  content: "\e9ac";
}
.icon-locked:before {
  content: "\e9ae";
}
.icon-unlocked:before {
  content: "\e9af";
}
.icon-stack1:before {
  content: "\e939";
}
.icon-stack2:before {
  content: "\e93a";
}
.icon-lamp:before {
  content: "\e93b";
}
.icon-lamp1:before {
  content: "\e93c";
}
.icon-grid:before {
  content: "\e91f";
}
.icon-tools:before {
  content: "\e9b4";
}
.icon-resize:before {
  content: "\e93d";
}
.icon-resize1:before {
  content: "\e93e";
}
.icon-download1:before {
  content: "\e9b5";
}
.icon-calculator1:before {
  content: "\e90d";
}
.icon-stats:before {
  content: "\e90e";
}
.icon-stats1:before {
  content: "\e90f";
}
.icon-rotate:before {
  content: "\e93f";
}
.icon-rotate1:before {
  content: "\e940";
}
.icon-reply:before {
  content: "\e941";
}
.icon-forward:before {
  content: "\e942";
}
.icon-retweet:before {
  content: "\e943";
}
.icon-dollar:before {
  content: "\e944";
}
.icon-dollar1:before {
  content: "\e945";
}
.icon-coins:before {
  content: "\e946";
}
.icon-bookmark:before {
  content: "\e947";
}
.icon-popout:before {
  content: "\e948";
}
.icon-popin:before {
  content: "\e91e";
}
.icon-checked:before {
  content: "\e949";
}
.icon-error:before {
  content: "\e94a";
}
.icon-add:before {
  content: "\e94b";
}
.icon-minus2:before {
  content: "\e94c";
}
.icon-zip:before {
  content: "\e94e";
}
.icon-zip1:before {
  content: "\e94f";
}
.icon-tags:before {
  content: "\e951";
}
.icon-switch:before {
  content: "\e952";
}
.icon-file-add:before {
  content: "\e9ce";
}
.icon-file3:before {
  content: "\e9cf";
}
.icon-reload:before {
  content: "\e9d0";
}
.icon-refresh1:before {
  content: "\e9d1";
}
.icon-graph:before {
  content: "\e9d2";
}
.icon-bars1:before {
  content: "\e9d3";
}
.icon-chart7:before {
  content: "\e9d4";
}
.icon-toggle-left:before {
  content: "\e953";
}
.icon-toggle-right1:before {
  content: "\e954";
}
.icon-trending_down:before {
  content: "\e927";
}
.icon-trending_up:before {
  content: "\e928";
}
.icon-Portal_Directories:before {
  content: "\e902";
}
.icon-add-outline:before {
  content: "\e956";
}
.icon-add-solid:before {
  content: "\e957";
}
.icon-album:before {
  content: "\e958";
}
.icon-artist:before {
  content: "\e959";
}
.icon-calculator11:before {
  content: "\e95a";
}
.icon-calendar2:before {
  content: "\e95b";
}
.icon-chart-bar:before {
  content: "\e95c";
}
.icon-chart-pie:before {
  content: "\e95d";
}
.icon-chat-bubble-dots:before {
  content: "\e95e";
}
.icon-checkmark3:before {
  content: "\e95f";
}
.icon-close:before {
  content: "\e960";
}
.icon-compose:before {
  content: "\e961";
}
.icon-credit-card:before {
  content: "\e962";
}
.icon-currency-dollar:before {
  content: "\e963";
}
.icon-dots-horizontal-double:before {
  content: "\e964";
}
.icon-edit-pencil:before {
  content: "\e965";
}
.icon-menu:before {
  content: "\e966";
}
.icon-music-notes:before {
  content: "\e967";
}
.icon-music-playlist:before {
  content: "\e968";
}
.icon-play-outline:before {
  content: "\e969";
}
.icon-playlist:before {
  content: "\e96a";
}
.icon-save-disk:before {
  content: "\e96b";
}
.icon-stand-by:before {
  content: "\e96c";
}
.icon-user-add:before {
  content: "\e96d";
}
.icon-user-solid-circle:before {
  content: "\e96e";
}
.icon-user-solid-square:before {
  content: "\e96f";
}
.icon-chart8:before {
  content: "\e9d5";
}
.icon-refresh2:before {
  content: "\e9d6";
}
.icon-eyedropper:before {
  content: "\e91b";
}
.icon-droplet:before {
  content: "\e938";
}
.icon-paint-format:before {
  content: "\e9b0";
}
.icon-file-picture1:before {
  content: "\e9b1";
}
.icon-quotes-left:before {
  content: "\e9b2";
}
.icon-quotes-right:before {
  content: "\e9b7";
}
.icon-magic-wand:before {
  content: "\e9b8";
}
.icon-bin:before {
  content: "\ea6a";
}
.icon-list-numbered:before {
  content: "\ea80";
}
.icon-list:before {
  content: "\ea9b";
}
.icon-list2:before {
  content: "\ea9c";
}
.icon-make-group:before {
  content: "\ea9d";
}
.icon-font-size:before {
  content: "\ea9e";
}
.icon-bold:before {
  content: "\ea9f";
}
.icon-underline:before {
  content: "\eaa0";
}
.icon-italic:before {
  content: "\eaa1";
}
.icon-strikethrough:before {
  content: "\eaa2";
}
.icon-superscript:before {
  content: "\eaa3";
}
.icon-subscript:before {
  content: "\eaa4";
}
.icon-text-color:before {
  content: "\eaa5";
}
.icon-pilcrow:before {
  content: "\eaa6";
}
.icon-ltr:before {
  content: "\eaa7";
}
.icon-rtl:before {
  content: "\eaa8";
}
.icon-paragraph-left:before {
  content: "\eaa9";
}
.icon-paragraph-center:before {
  content: "\eaaa";
}
.icon-paragraph-right:before {
  content: "\eaab";
}
.icon-paragraph-justify:before {
  content: "\eaac";
}
.icon-indent-increase:before {
  content: "\eaad";
}
.icon-indent-decrease:before {
  content: "\eaae";
}
.icon-embed2:before {
  content: "\eaaf";
}
.icon-mail3:before {
  content: "\eab0";
}
.icon-circle-up:before {
  content: "\ea72";
}
.icon-circle-right:before {
  content: "\ea73";
}
.icon-circle-down:before {
  content: "\ea74";
}
.icon-circle-left:before {
  content: "\ea75";
}
.icon-image1:before {
  content: "\e970";
}
.icon-images:before {
  content: "\e971";
}
.icon-headphones1:before {
  content: "\e972";
}
.icon-music1:before {
  content: "\e975";
}
.icon-file-picture:before {
  content: "\e976";
}
.icon-file-music:before {
  content: "\e977";
}
.icon-file-play:before {
  content: "\e978";
}
.icon-file-video:before {
  content: "\e97a";
}
.icon-barcode:before {
  content: "\e97b";
}
.icon-qrcode:before {
  content: "\e97d";
}
.icon-stats-bars:before {
  content: "\e9ad";
}
.icon-target:before {
  content: "\e9b3";
}
.icon-switch1:before {
  content: "\e9b6";
}
.icon-menu1:before {
  content: "\e9bd";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-home:before {
  content: "\e900";
}
.icon-price-tags:before {
  content: "\e936";
}
.icon-history:before {
  content: "\e94d";
}
.icon-alarm:before {
  content: "\e950";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-user-minus:before {
  content: "\e974";
}
.icon-hour-glass:before {
  content: "\e979";
}
.icon-spinner3:before {
  content: "\e97c";
}
.icon-spinner9:before {
  content: "\e982";
}
.icon-spinner10:before {
  content: "\e983";
}
.icon-equalizer:before {
  content: "\e994";
}
.icon-equalizer2:before {
  content: "\e995";
}
.icon-warning1:before {
  content: "\e901";
}
.icon-database1:before {
  content: "\e904";
}
.icon-chart4:before {
  content: "\e906";
}
.icon-chart-alt:before {
  content: "\e907";
}
.icon-bars:before {
  content: "\e908";
}
.icon-bars-alt:before {
  content: "\e909";
}
.icon-pencil:before {
  content: "\e955";
}
.icon-video-camera:before {
  content: "\f03d";
}
.icon-image:before {
  content: "\f03e";
}
.icon-photo:before {
  content: "\f03e";
}
.icon-picture-o:before {
  content: "\f03e";
}
.icon-edit:before {
  content: "\f044";
}
.icon-pencil-square-o:before {
  content: "\f044";
}
.icon-info-circle:before {
  content: "\f05a";
}
.icon-calendar3:before {
  content: "\f073";
}
.icon-bar-chart1:before {
  content: "\f081";
}
.icon-bar-chart-o1:before {
  content: "\f081";
}
.icon-globe:before {
  content: "\f0ac";
}
.icon-folder-o:before {
  content: "\f114";
}
.icon-folder-open-o:before {
  content: "\f115";
}
.icon-keyboard-o:before {
  content: "\f11c";
}
.icon-flag-checkered:before {
  content: "\f11e";
}
.icon-area-chart1:before {
  content: "\f1ff";
}
.icon-pie-chart1:before {
  content: "\f202";
}
.icon-toggle-off:before {
  content: "\f204";
}
.icon-toggle-on:before {
  content: "\f205";
}
.icon-times-rectangle-o1:before {
  content: "\f2d5";
}
.icon-window-close-o1:before {
  content: "\f2d5";
}
.icon-download:before {
  content: "\f019";
}
.icon-bar-chart:before {
  content: "\f080";
}
.icon-bar-chart-o:before {
  content: "\f080";
}
.icon-wrench:before {
  content: "\f0ad";
}
.icon-tasks:before {
  content: "\f0ae";
}
.icon-floppy-o:before {
  content: "\f0c7";
}
.icon-save:before {
  content: "\f0c7";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-sort:before {
  content: "\f0dc";
}
.icon-unsorted:before {
  content: "\f0dc";
}
.icon-sort-desc:before {
  content: "\f0dd";
}
.icon-sort-down:before {
  content: "\f0dd";
}
.icon-sort-asc:before {
  content: "\f0de";
}
.icon-sort-up:before {
  content: "\f0de";
}
.icon-angle-double-left:before {
  content: "\f100";
}
.icon-angle-double-right:before {
  content: "\f101";
}
.icon-angle-double-up:before {
  content: "\f102";
}
.icon-angle-double-down:before {
  content: "\f103";
}
.icon-angle-left:before {
  content: "\f104";
}
.icon-angle-right:before {
  content: "\f105";
}
.icon-angle-up:before {
  content: "\f106";
}
.icon-angle-down:before {
  content: "\f107";
}
.icon-caret-square-o-down:before {
  content: "\f150";
}
.icon-toggle-down:before {
  content: "\f150";
}
.icon-caret-square-o-up:before {
  content: "\f151";
}
.icon-toggle-up:before {
  content: "\f151";
}
.icon-caret-square-o-right:before {
  content: "\f152";
}
.icon-toggle-right:before {
  content: "\f152";
}
.icon-sort-alpha-asc:before {
  content: "\f15d";
}
.icon-sort-alpha-desc:before {
  content: "\f15e";
}
.icon-sort-amount-asc:before {
  content: "\f160";
}
.icon-sort-amount-desc:before {
  content: "\f161";
}
.icon-soundcloud:before {
  content: "\f1be";
}
.icon-database:before {
  content: "\f1c0";
}
.icon-file-pdf-o:before {
  content: "\f1c1";
}
.icon-file-word-o:before {
  content: "\f1c2";
}
.icon-file-excel-o:before {
  content: "\f1c3";
}
.icon-file-powerpoint-o:before {
  content: "\f1c4";
}
.icon-file-image-o:before {
  content: "\f1c5";
}
.icon-file-photo-o:before {
  content: "\f1c5";
}
.icon-file-picture-o:before {
  content: "\f1c5";
}
.icon-file-archive-o:before {
  content: "\f1c6";
}
.icon-file-zip-o:before {
  content: "\f1c6";
}
.icon-file-audio-o:before {
  content: "\f1c7";
}
.icon-file-sound-o:before {
  content: "\f1c7";
}
.icon-area-chart:before {
  content: "\f1fe";
}
.icon-pie-chart:before {
  content: "\f200";
}
.icon-line-chart:before {
  content: "\f201";
}
.icon-hourglass-2:before {
  content: "\f252";
}
.icon-hourglass-half:before {
  content: "\f252";
}
.icon-percent:before {
  content: "\f295";
}
.icon-window-maximize:before {
  content: "\f2d0";
}
.icon-window-minimize:before {
  content: "\f2d1";
}
.icon-window-restore:before {
  content: "\f2d2";
}
.icon-times-rectangle:before {
  content: "\f2d3";
}
.icon-window-close:before {
  content: "\f2d3";
}
.icon-times-rectangle-o:before {
  content: "\f2d4";
}
.icon-window-close-o:before {
  content: "\f2d4";
}
