@font-face {
    font-family: 'Inter';
    src: url('../../fonts/inter/Inter-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'Inter-SemiBold';
    src: url('../../fonts/inter/Inter-SemiBold.ttf');
    font-weight: normal;
    font-style: normal;
}

* {
    font-size: 14px;
    color: #495298;
    letter-spacing: 0;
    font-family: Inter;
}

* {
    font-size: 14px;
    /* color: #656AA3; */
    letter-spacing: 0;
}

/*************style start ***************/

.iconReport {
    padding: 8px;
}

.iconReport .box {
    font-size: 19px;
    background: #f7f8ff;
    float: none;
    display: block;
    padding: 15px;
}

.iconReport .box.active {
    background: #51a1e2;
    color: #fff;
}

.icon-sub-contact .path1:before {
    content: "\eab5";
    color: rgb(101, 106, 163);
}

.icon-sub-contact .path2:before {
    content: "\eab6";
    margin-left: -1.14453125em;
    color: rgb(223, 0, 0);
}

.icon-sub-contract .path1:before {
    content: "\eab7";
    color: rgb(101, 106, 163);
}

.icon-sub-contract .path2:before {
    content: "\eab8";
    margin-left: -0.9111328125em;
    color: rgb(223, 0, 0);
}

/*
  .icon-add-contact .path1:before {
    content: "\eab1";
    color: rgb(101, 106, 163);
  }
  .icon-add-contact .path2:before {
    content: "\eab2";
    margin-left: -1.095703125em;
    color: rgb(39, 174, 96);
  }
  .icon-add-contract .path1:before {
    content: "\eab3";
    color: rgb(101, 106, 163);
  }
  .icon-add-contract .path2:before {
    content: "\eab4";
    margin-left: -0.826171875em;
    color: rgb(39, 174, 96);
  }*/

/************style end ***************/
.landingPage {
    float: left;
}

.loginWelcome {
    background-image: url(../../../assets/images/loginWelcomeKorrect.png);
    /*../../assets/images/testWelcome.jpg*/
    /*../../../assets/images/loginWelcomeKorrect.png*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: fixed;
    right: 0;
    height: 100%;
}

.fullImage {
    height: 100% !important;
    width: 100% !important;

}


.miniCreatorImg.shadowCoverPicture {
    height: 130px !important;
    width: 150px !important;
    position: relative;
}

.shadowCoverPicture {
    overflow: hidden;
    height: 280px;
    width: 250px !important;
}

.backgroundTransparence {
    background: rgb(255 255 255 / 30%);
}

a {
    text-decoration: none;
    color: #495298;
}

script {
    display: none !important;
}

h1 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    padding: 4px 0px;
}

/*
h2{
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 2px 4px;
    color: #ffffff;
}*/

h2, h2 span, h2 i {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    padding: 5px;
    /*text-transform: capitalize;
    color: #ffffff;*/
}

.box-header-main h2{
    color: #fff;
}


h3, h3 span, h3 i {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    padding: 1px;
    color: #656AA3;
}

h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}



.body.landing,
.box_bg,
.landing {
    background: #f6f8ff !important;
    color: #656AA3;
}

.leftHeaderMenu {
    position: fixed;
    height: 100%;
    background: linear-gradient(180deg, rgba(39, 73, 81, 1) 0%, rgba(17, 35, 40, 1) 100%) !important;
    width: 70px;
}

.rightHeaderMenu {
    width: calc(100% - 70px);
}

.userProfilevatar {
    width: 40px;
    height: 40px;
}

/*Start On Off Switch Start*/

.switch {
    position: relative;
    display: inline-block;
    width: 64px !important;
    height: 28px;
    position: relative !important;
    margin: -5px 0 0 0px !important;
    float: left !important;
}

.switch input, .switchMini input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switchMini {

    position: relative;
    display: inline-block;
    position: relative !important;
    float: left !important;
    padding: 0;
    width: 50px !important;
    height: 24px;
}


.switchMini .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 13px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}



.dockingSlider {
    height: 86%;
    position: absolute;
    z-index: 3;
    top: 5%;
    left: 0;
    background-color: #ffffff;
    transition: 0.5s;
    border-radius: 10px;
    box-shadow: inset 0px 0px 10px 0px #d2d7ff;
}

.dockingSliderWizard {
    z-index: 3;
    position: absolute;
    top: 150%;
    right: 0;
    background-color: #ffffff;
    transition: 0.5s;
    border-radius: 10px;
    box-shadow: inset 0px 0px 10px 0px #d2d7ff;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #da440f;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 22px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.sliderOff.slider:before {
    left: 2px;
}

input:checked + .slider {
    background-color: #27AE60;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

.slider.round {
    border-radius: 15px;
    object-fit: cover;
}


.slider.round:before {
    border-radius: 50%;
}


/**/
*:focus {
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 5px #719ECE;
}

.logo {
    height: 50px
}

.form-format.collapsed .label-entries.hidden {
    display: none !important;
}

.form-format .hidden {
    display: none !important;
}

/*Start Box Layout*/

.box {
    float: left;
    border: solid 1px #e7ebfb;
    width: 100%;
    float: left;
    background: #ffffff;
    padding: 0px;
    overflow: hidden;
}

.box-header {
    cursor: default;
    width: 100%;
    float: left;
    background: #E7EBFB;
    padding: 8px 16px;
    font-weight: bold;
    text-transform: capitalize;
}

.box-content {
    float: left;
    width: 100%;
    padding: 14px;
}

.box-footer {
    box-shadow: 0px 0px 2px 0px #f5f3ff;
    float: left;
    width: 100%;
    text-align: right;
    padding: 16px;
}

/*End Box Layout*/

.pull-none {
    float: none !important;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.block {
    display: block !important;
}

.inline {
    display: inline-block !important;
}

.displayFlex {
    display: inline-flex !important;
}


.show {
    display: block !important;
}

.hide {
    display: none !important;
}

.italic {
    font-style: italic;
}

.capitalize {
    text-transform: capitalize;
}

hr {
    margin: 8px 0 8px 0;
    border: none;
    border-top: solid 1px #E3E6EC;
    float: left;
    width: 100%;
}

.dottedHr {
    border-top: 1px dashed #f2f3f8;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-underline {
    text-decoration: underline;
}

.text-bold {
    font-weight: bold;
}

.text-normal {
    font-weight: normal;
}

.text-s {
    font-size: 11px !important;
}

.text-sl {
    font-size: 13px !important;
}

.text-m {
    font-size: 15px !important;
}

.text-l {
    font-size: 20px !important;
}

.text-xl {
    font-size: 20px !important;
    line-height: 20px;
    border: none;
}

.text-xxl {
    font-size: 30.4px !important;
    line-height: 32px;
    border: none;
}

.circularLogo {
    position: relative;
}

.circularLogo img {
    border-radius: 50%;
    /*box-shadow: 0px 1px 9px #acc7f7;
        border: 1px solid #E3E6EC;*/
    width: 70px;
    height: 70px;
}

.circularIcon img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.circularLogo i {
    position: absolute;
    font-size: 40px;
    color: #ffffff;
    margin: 5px;
}

.paddingCenterBox {
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
    position: relative;
}

.verticalCentering {
    top: 30%;
    position: fixed;

}

.verticalAlignTop {
    vertical-align: top;
}

.centerWindow {
    margin: 0 auto !important;
    float: none !important;
    display: block;
}

.border-bottom {
    border-bottom: solid 2px #e3e6ff !important;
}

.border-right {
    border-right: solid 2px #e3e6ff !important;
}

.border-left {
    border-left: solid 2px #e3e6ff !important;
    border-left-width: 2px !important;
    border-left-style: solid !important;
}

.border-top {
    border-top: solid 1px #f3f4ff !important;
}

.borderNone {
    border: none !important;
}

.borderSolid {
    border: solid 1px lightgrey;
}


.borderSolidDark {
    border: solid 1px #dde0ff;
}

.boxShadow {
    box-shadow: 0px 0px 0px black, 0 0 3px lightgrey, 0 0 0px black;
}

.text-Shadow {
    text-shadow: 0px 0px 4px #000000;
}

.borderRounded {
    border-radius: 12px !important;
}


.borderRoundedMedium {
    border-radius: 6px !important;
}

.borderReset {
    border-radius: 0px !important;
}

.roundedButtons {
    border-radius: 20px !important;
}


.cursor-hand,
.cursor-pointer {
    cursor: pointer !important;
}

.full-width {
    width: 100% !important;
}

.width-auto {
    width: auto !important;
}

.minWidth-auto {
    min-width: auto !important;
}

.width150 {
    width: 150px !important;
}

.width200 {
    width: 200px !important;
}

.full-height {
    height: 100% !important;
}

.full-height-vh {
    height: 100vh !important;
}

.full-height-vhm {
    height: 74vh !important;
}

.height150 {
    height: 150px !important;
}

.height200 {
    height: 200px !important;
}

.height320 {
    height: 320px !important;
}

.height400 {
    height: 400px !important;
}

.login-box {
    margin-left: auto !important;
    margin-right: auto !important;
    float: none !important;
}

input, textarea, select {
    border: solid #DEDEDE 1px;
    padding: 8px 8px;
    border-radius: 5px;
    height: 30px;
    color: #47529d;
}

[class*="icon-"] {
    font-size: 10px;
    float: left;
}

input[type=radio], input[type=checkbox] {
    height: auto;
    float: left;
    margin: 2px 5px 0 0;
}

input.slim {
    height: 30px;
    padding: 2px;
}

.form-format .label-entries input[type=text], .form-format .label-entries input[type=email], .form-format .label-entries input[type=password], .form-format .label-entries input[type=number], .form-format .label-entries textarea, .label-entries select {
    float: left;
    width: 100%;
    font-size: 14px;
    font-family: sans-serif;
}

.input-text-wrap [type=text] {
    /*display: block;
    width: 100%;
    padding: 0;
    font-size: 13px;
    line-height: 24px;
    color: #294661;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #d4dadf;
    box-shadow: 0 1px 0 transparent;
    transition: border-color .3s, box-shadow .3s;*/
    border: 1px solid #a52f2f;
    /* Border color */
    /* Border radius */
    padding: 8px 12px;
    /* Padding inside the input box */
    font-size: 14px;
    /* Font size */
    width: 180px;
    /* Width of the input box */
    box-sizing: border-box !important;
    /* Ensure padding and bor*/
}

/*
input[type="file"] {
    display: none;
}*/

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.pagination ul {
    padding: 0px;
    margin: 0px;
}

.pagination ul li {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    float: left;
    list-style: none;
    margin: 0 1px;
    padding: 10px 10px;
}

.pagination ul li.active {
    color: #fff;
    background: #1F947C;
}

.m0 {
    margin: 0 !important;
}

.m1 {
    margin: 1px !important;
}

.ms {
    margin: 2px !important;
}

.mm {
    margin: 10px !important;
}

.mLarge {
    margin: 16px !important;
}

.mts {
    margin-top: 2px !important;
}

.mtss {
    margin-top: 2px !important;
}

.mrs {
    margin-right: 2px !important;
}

.mbs {
    margin-bottom: 3px !important;
}

.mls {
    margin-left: 2px !important;
}

.mt {
    margin-top: 5px !important;
}

.mr {
    margin-right: 5px !important;
}

.mb {
    margin-bottom: 4px !important;
}

.ml {
    margin-left: 4px !important;
}

.mtl {
    margin-top: 10px !important;
}

.mtll {
    margin-top: 16px !important;
}

.mrl {
    margin-right: 10px !important;
}

.mrxl {
    margin-right: 20px !important;
}

.mlxl {
    margin-left: 20px !important;
}

.mbl {
    margin-bottom: 10px !important;
}

.mbl {
    margin-bottom: 16px !important;
}

.mll {
    margin-left: 10px !important;
}

.mlxxl {
    margin-left: 48% !important;
}

.mtxl {
    margin-top: 20px !important;
}

.mtxxl {
    margin-top: 30px !important;
}

.mbxl {
    margin-bottom: 30px !important;
}

.p0 {
    padding: 0 !important;
}

.p1 {
    padding: 1px !important;
}

.ps {
    padding: 4px !important;
}

.pm {
    padding: 10px !important;
}

.pLarge {
    padding: 15px !important;
}

.pts {
    padding-top: 5px !important;
}

.prs {
    padding-right: 2px !important;
}

.pbs {
    padding-bottom: 5px !important;
}

.pls {
    padding-left: 3px !important;
}

.pt {
    padding-top: 4px !important;
}

.ptm {
    padding-top: 6px !important;
}

.pr {
    padding-right: 4px !important;
}

.pb {
    padding-bottom: 4px !important;
}

.pl {
    padding-left: 4px !important;
}

.ptl {
    padding-top: 10px !important;
}

.ptll {
    padding-top: 50px !important;
}

.ptxl {
    padding-top: 20px !important;
}

.ptml {
    padding-top: 30px !important;
}

.prl {
    padding-right: 10px !important;
}

.prll {
    padding-right: 20px !important;
}

.prlxl {
    padding-right: 30px !important;
}

.pbl {
    padding-bottom: 10px !important;
}

.pll {
    padding-left: 10px !important;
}

.plx {
    padding-left: 20px !important;
}

.plxl {
    padding-left: 30px !important;
}

.prxl {
    padding-right: 30px !important;
}

.prx {
    padding-right: 20px !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden;
}

.positionRelative {
    position: relative !important;
}

.positionAbsolute {
    position: absolute !important;
}

.positionFixed {
    position: fixed !important;
}

.alignBottom {
    bottom: 0px !important;
    ;
}

.rightPositioning {
    right: 2px;
}

.forceSingleLine {
    white-space: nowrap;
}

.forceScroll {
    width: 150%;
}


.allowHscroll {
    overflow-x: auto;
    padding: 0 4px !important;
    margin: 10px 0px !important;
}

.allowVscroll {
    overflow-y: auto !important;
}

.overflowHidden {
    overflow: hidden !important;
}

.overflowVisible {
    overflow: visible !important;
}

.allowHscroll::-webkit-scrollbar, .block-ui-main::-webkit-scrollbar {
    height: 10px;
    background-color: #F5F5F5;
}

.allowVscroll::-webkit-scrollbar, .block-ui-main::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.allowHscroll::-webkit-scrollbar-track, .block-ui-main::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.allowVscroll::-webkit-scrollbar-track, .block-ui-main::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.allowVscroll::-webkit-scrollbar-thumb, .allowHscroll::-webkit-scrollbar-thumb, .block-ui-main::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #47529d;
    background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
}

.bgTransparent {
    background: transparent !important;
}

/*Start Icon CSS*/
.iconLogo {
    padding: 10px;
}

.iconLogo .box {
    font-size: 19px;
    /*
        background: #f7f8ff;
    */
    box-shadow: 0px 0px 1px #495298;
    float: none;
    display: block;
    padding: 19px;
}

i.box:hover {
    background: #ffffff;
    box-shadow: 0px 0px 20px 8px #eef0fb;
}

.iconLogo .box.active {
    background: #3C88F7;
    color: #fff;
}

.iconLogo span.active {
    color: #3C88F7;
}

.iconLogo span {
    color: #606785;
    width: 63px;
    float: left;
    text-align: center;
    height: 40px;
}

/*End Icon CSS*/

/** Start MENU **/
ul {
    list-style: none;
    padding: 0;
    margin: 0px;
    /*background: #f5f6fc;*/
}

.menu-header {
    padding: 10px;
    font-weight: bold;
}

.main-menu li {
    float: left;
    width: 100%;
    border: solid 1px #dde0ff;
    margin: 1px;
}

.main-menu li a {
    padding: 8px;
    /*font-weight: bold;*/
}

.main-menu li.active,
.main-menu li.active a {
    background: #3478F7;
    color: #ffffff;
}

/** End MENU **/

/** Start Navigation **/

.nav {
    margin: 5px 0 0 0;
    margin-bottom: 6px;
    list-style: none;
    padding: 0px 5px;
    float: left;
}

.nav li, .nav-horizontal {
    border: solid 1px rgba(200, 202, 222, 0.50);
    float: left;
    border-radius: 5px;
    background: #ffffff;
    border-color: #e9ebf5;
    padding: 8px 16px;
    border-radius: 3px;
    color: #b2b4d4;
    font-weight: bold;
}

.nav-tabs li.active, .green-nav-tabs li.active, .nav-horizontal.active {
    border: solid 1px #3dae84 !important;
    background: none;
    background: #fff;
}

.nav ul li, ul.nav lis {
    border: solid 1px #b8bce1;
    border-radius: 5px;
    margin: 3px 0px;
    float: left;
}

.nav ul li:hover {
    background: #eaf7ff;
}

.nav ul li:hover a {
    color: #47529d;
}

.nav.nav-tabs li.active a {
    color: #41445c !important;
}

/*
.nav ul li a, .nav-tabs li a, .nav-pills li a {
    padding: 4px 0;
    float: left;
}
.nav-tabs li, .nav-pills li {
    float: left;
    margin: 0 4px 0 0;
    background-color: #f3f5ff;
}

.nav-pills > .active > a, .nav-pills > .active > a:hover {
    border-bottom: solid 3px #2d3a8f;
    border-right: 10px;
}

.nav-pills li {
    background: none !important;
    border: none !important;
    padding: 0px !important;
}

.nav-pills li a {
    padding: 4px 16px;
    color: #3478F7;
    font-weight: bold;
}

.nav-pills li a.active {
    border-bottom: solid 3px #3478F7 !important;
}
*/

.nav-tabs li.active ,  .green-nav-tabs li.active  {
    border:solid 1px #3dae84 !important;
    color: #000;

}

.nav-tabs li.active a {
    color: #201e1e;
}

.nav li {
    border: solid 1px rgba(200, 202, 222, 0.50);
    float: left;
    background: #ffffff;
    border-color: #e9ebf5;
    padding: 8px 16px;
    color: #b2b4d4;
    font-weight: bold;
    margin: 0 8px 0 0px;
}

.nav-header {
    padding: 0 0px 0 6px;
    color: #2a6ca8;
}

a:hover {
    text-shadow: 0px 0px 1px #afaeae;
    cursor: default;
}

/** End Navigation **/




/*
.nav li {
    border: solid 1px rgba(200, 202, 222, 0.50);
    float: left;
    border-radius: 5px;
    background: #ffffff;
    border-color: #e9ebf5;
    padding: 8px 16px;
    border-radius: 3px;
    color: #b2b4d4;
    font-weight: bold;
}

.nav-header {
    padding: 0 0px 0 6px;
    color: #2a6ca8;
}

.nav {
    margin: 5px 0 0 0;
    margin-bottom: 6px;
    list-style: none;
    padding: 0px 5px;
    float: left;
}

.nav-tabs li.active a {
    color: #FFFFFF;
}

.nav-tabs li, .nav-pills li {
    float: left;
    margin: 0 4px 0 0;
    background-color: #f3f5ff;
}

.nav-pills > .active > a, .nav-pills > .active > a:hover {
    border-bottom: solid 3px #2d3a8f;
    border-right: 10px;
}

.nav-pills li {
    background: none !important;
    border: none !important;
    padding: 0px !important;
}

.nav-pills li a {
    padding: 4px 16px;
    color: #3478F7;
    font-weight: bold;
}

.nav-pills li a.active {
    border-bottom: solid 3px #3478F7 !important;
}

.nav ul li, ul.nav lis {
    border: solid 1px #f3f5ff;
    border-radius: 5px;
    margin: 3px 0px;
    float: left;
}

.nav ul li:hover {
    background: #eaf7ff;
}

.nav ul li:hover a {
    color: #47529d;
}

.nav ul li a, .nav-tabs li a, .nav-pills li a {
    padding: 4px 0;
    float: left;
}


.nav-tabs li.active, .nav-tabs li.active a {
    background: #3478F7;
    color: #ffffff;
}

*/
/*End Menu*/


/*Start Hover Box Start*/
/*.hover-block.textboxDropdownList {
    width: 160px;
}*/


.hover-box li {
    list-style: none;
    margin: 1px 0px !important;
    padding: 0px 2px;
    float: left;
    width: 100%;
}

.hover-block.textboxDropdownList.blockWidth {
    width: 172px;
}

.hover-block {
    display: none;
    position: absolute;
    /* width: 178px; */
    margin: 12px 0 0 -6px;
    padding: 5px;
    background: #fff;
    z-index: 9999;
    text-align: left;
    min-width: 220px;
}

.plain-hover-block {
    display: none;
}

.hover-box:hover>.hover-block,
.plain-hover-box:hover>.plain-hover-block,
.justShow {
    display: block !important;
}

.ql-toolbar {
    display: none;
}


.ql-container {
    border-top: 1px solid #ccc !important;
}

.hover-box:hover>.ql-toolbar {
    display: block !important;

}

/*
.hover-box {
    margin: 0 0 5px 5px !important;
}*/

.textboxDropdownList {
    /* width: 200px; */
    width: 35vw;
}

.box.textboxDropdownList {
    width: 30vw;
    float: none !important;
    border: solid 1px #e7ebfb;
}

.vertical .hover-block.textboxDropdownList {
    margin: 27px 0 0 0px;
}

.vertical.hover-box .btn {
    width: 132px;
    text-shadow: none;
}

.pabsolute {
    position: absolute;
}

.verticalMediumWide .hover-block {
    margin: 0px 0 -4px 0px;
    padding: 5px;
    box-shadow: 0 2px 7px 0 rgba(190, 190, 190, 0.50);
}

.verticalMediumWide .hover-block {
    margin: 0px 0 -4px 0px;
    padding: 5px;
    box-shadow: 0 2px 7px 0 rgba(190, 190, 190, 0.50);
}

.verticalMediumWide.hover-box .btn {
    width: auto;
    text-shadow: none;
}

.verticalMediumWide .hover-block.textboxDropdownList {
    overflow: auto;
    color: #47529d;
    /*height: 345px; width : 250px;*/
}

.verticalMediumWide .hover-block.textboxDropdownList.doubleColumn {
    width: 370px;
}

.verticalMediumWide .hover-block.textboxDropdownList.doubleColumn li {
    width: 47%;
}

.verticalMediumWide {
    position: relative;
}

/*End Hover Box*/

.shadowInset {
    box-shadow: inset 10px 10px 10px -7px #d2d7ff;
}

.shadowOutset {
    box-shadow: 10px -10px 10px -7px #d2d7ff;
}

.shadowCoverPicture {
    width: 240px;
    height: 325px;
}

.shadowCoverPicture .coverPicture img {
    height: 230px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #edf3f4;
}

.coverPictureContainer {
    min-height: 230px;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.miniCreatorImg.shadowCoverPicture img {
    width: 100%;
    height: auto !important;
}

.miniCreatorImg .bottomOverlayLabel {
    position: absolute;
    bottom: 0px;
    background: rgba(255, 255, 255, 0.85) !important;
    width: 100%;
    padding: 2px;
    font-weight: bold;
}

.noBorder {
    border: none !important;
}

.noShadow {
    box-shadow: none !important;
}

.no-scroll {
    overflow: hidden !important;
}

.noBorderRadius {
    border-radius: 0px !important;
}

.heightReset {
    height: auto;
}

.leftReset {
    left: 0px;
}

.rightReset {
    right: 0px;
}

.errorMessage {
    background: #fff5f5;
    border: solid 2px #ff9396;
    /*border-radius: 5px;*/
    padding: 20px;
    min-width: 100px;
}

.successMessage {
    background: #e1faf5;
    border: solid 1px #77cbbc;
    padding: 20px;
    min-width: 100px;
}

.infoMessage {
    background: #ecf6ff;
    border: solid 1px #77a5cb;
    /*border-radius: 5px;*/
    padding: 20px;
    min-width: 100px;
}

.normalMessage {
    background: #eef0ef;

    /*border-radius: 5px;*/
    padding: 20px;
    min-width: 100px;
}

/*Start popupSlider*/
/*
.EZDrawer {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
}

.EZDrawer .EZDrawer__checkbox {
    display: none;
}

.EZDrawer .EZDrawer__checkbox:checked~.EZDrawer__overlay {
    display: block;
    opacity: 1;
}

.EZDrawer .EZDrawer__checkbox:checked~.EZDrawer__container {
    visibility: visible;
    transform: translate3d(0, 0, 0) !important;
    overflow-y: auto;
}

.EZDrawer .EZDrawer__overlay {
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}

.EZDrawer .EZDrawer__container {
    position: absolute;
    visibility: hidden;
    background: white;
    transition: all;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
}

.react-drawer-medium {
    width: 800px !important;
}

.react-drawer-large {
    width: 1000px !important;
}*/

.EZDrawerLeft {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    margin: 0;
    padding: 0;
}

.EZDrawerRight {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    margin: 0;
    padding: 0;
}

.EZDrawer .EZDrawer__checkbox {
    display: none;
}

.EZDrawer .EZDrawer__checkbox:checked~.EZDrawer__overlay {
    display: block;
    opacity: 1;
}

.EZDrawer .EZDrawer__checkbox:checked~.EZDrawer__container {
    overflow-y: auto;
    overflow-x: hidden;
    visibility: visible;
    background: rgba(0, 0, 0, 0.4);
    transform: translate3d(0, 0, 0) !important;
    width: 100% !important;
}

.EZDrawer .EZDrawer__overlay {
    display: none;
    position: fixed;
    top: 0;
}

.EZDrawer .EZDrawer__container {
    position: absolute;
    visibility: hidden;
    background: white;
    transition: all;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0);
}

.react-drawer-medium {
    width: 800px !important;
}

.react-drawer-large {
    width: 1000px !important;
}

@media screen and (max-width: 768px) {
    .react-drawer-medium {
        width: 100% !important;
    }

    .react-drawer-large {
        width: 100% !important;
    }
}


.labelOverlay {
    position: absolute !important;
    display: grid;
}

.listStyle {
    list-style: none;
}

/*End popupSlider*/

/*react-responsive-modal-modal*/
.react-responsive-modal-modal {
    padding: 0px;
    width: 95%;
    max-width: 95% !important;
    min-height: 80%;
}

.react-responsive-modal-closeButton {
    top: 4px;
    right: 4px;
    background-color: #ffffff;
    color: #fff;
    border: solid 1px #495298;


}




.table-responsive_big,
fieldset.scheduler-border>table-responsive_big {
    min-height: .01%;
    overflow-x: auto;
    padding: 1px;
}

fieldset.scheduler-border {
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
    border: 1px solid rgb(222, 222, 222);
    background: #fff;
}

legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
}

.alert {
    padding: 8px 14px;
    color: #fff !important;
    background: #6672c1;
    float: left;
    width: 100%;
    border-radius: 7px;
    text-align: center;
}

.alert-danger {
    background-color: #fd95a2;
    border-color: #ebccd1;
    color: #a94442;
}
/* Start Form Formatting*/


/*
.form-format {
    float: left;
    padding: 8px 0px;
    width: 100%;
}

.form-format label {
    text-align: left;
    float: left;
    width: 100%;
    padding: 0px;
    border-radius: 5px;
    margin: 0 0 7px 0;
    font-weight: bold
}

.form-format input[type=radio], .form-format input[type=checkbox] {
    height: auto;
    float: left;
    margin: 1px 5px 0 0;
}

.form-format .label-entries {
    float: left;
    width: 90%;
}

.form-format .label-entries input[type=text], .form-format .label-entries input[type=email], .form-format .label-entries input[type=password], .form-format .label-entries input[type=number], .form-format .label-entries textarea, .label-entries select {
    float: left;
    width: 100%;
    font-size: 14px;
    font-family: sans-serif;
}

::placeholder {
    color: #d8dae3;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
    cursor: not-allowed;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.label-entries.combined select, .label-entries.combined input[type=text] {
    width: calc(100% - 43px);
}

.label-entries.combined .btn {
    margin: 0 0 0 1px;
    float: right;
    padding: 2px 0 1px 0;
}


.form-format .label-entries input[type=text],
.form-format .label-entries input[type=email],
.form-format .label-entries input[type=password],
.form-format .label-entries input[type=number],
.form-format .label-entries textarea,
.label-entries select {
    float: left;
    width: 100%;
    font-size: 14px;
    font-family: sans-serif;
}


.label-entries.combined select,
.label-entries.combined input[type=text] {
    width: calc(100% - 43px);
}

.label-entries.combined .btn {
    margin: 0 0 0 1px;
    float: right;
    padding: 2px 0 1px 0;
}*/



.form-format {
    float: left;
    padding: 8px 0px;
    width: 100%;
}

/*
.form-format label {
text-align: right;
float: left;
width: 30%;
font-weight: bold;
margin: 5px 0px;
padding: 0 10px;
}

*/

.form-format label {
    text-align: left;
    float: left;
    width: 100%;
    padding: 0px;
    border-radius: 5px;
    margin: 0 0 7px 0;
    font-weight: bold
}

.form-format input[type=radio], .form-format input[type=checkbox] {
    height: auto;
    float: left;
    margin: 1px 5px 0 0;
}

.form-format .label-entries {
    float: left;
    width: 90%;
}

.form-format .label-entries input[type=text], .form-format .label-entries input[type=email], .form-format .label-entries input[type=password], .form-format .label-entries input[type=number], .form-format .label-entries textarea, .label-entries select {
    float: left;
    width: 100%;
    font-size: 14px;
    font-family: sans-serif;
}

::placeholder {
    color: #d8dae3;
}

input[disabled],select[disabled],textarea[disabled],input[readonly],select[readonly],textarea[readonly] {
    cursor: not-allowed;
    background-color: #f5f5f5;
    border-color: #ddd;
}

.label-entries.combined select, .label-entries.combined input[type=text] {
    width: calc(100% - 43px);
}

.label-entries.combined .btn {
    margin: 0 0 0 1px;
    float: right;
    padding: 2px 0 1px 0;
}

/*react-responsive-modal-modal*/
/*Start button*/
[class*="btn"] , .label-info {
    background: #ffffff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: bold;
    border-color: #64ADE4;
    float: left;
    margin: 0 5px;
    text-align: center;
}

[class*="btn"] i {
    font-size: 14px;
    /*margin: 0px 4px 0px 0px;*/
    line-height: 18px;
    float: left;
    border: none !important;
}


.btn-success[disabled], .btn-primary[disabled], .btn-danger[disabled], .btn-info[disabled], .btn-warning[disabled], .btn-grey[disabled], .btn-grey.caret {
    background-color: lightgrey !important;
}

[class*="btn"] i {
    font-size: 16px;
    margin: 0 2px;
    float: left;
    border: none !important;
}

.box-footer [class*="btn"] {
    float: right;
}

[class*="btn"].active {

    background: #3478F7;
    /*color: #ffffff;
    
    border-bottom: solid 2px #3478F7;
    background-color: #656AA3;
    color: #fff !important; 
    color: #3478F7;
    font-weight: bold;
    margin: 0 0 -2px 0;*/
}

.btn-s {
    padding: 5px 10px 5px 10px;
    font-size: 12px;
}

.btn-sm {
    padding: 5px;
    font-size: 12px;
    /* line-height: 1.5; */
}

.btn-xs {
    padding: 5px;
    font-size: 10px;
    font-weight: normal;
}

.btn-xxs {
    padding: 2px;
    font-size: 10px;
}

.btn-l {
    padding: 1px 2px;
    font-size: 11px;
    line-height: 1;
}

.btn-xl {
    padding: 8px;
    font-size: 15px;
    line-height: 1;
}


.btn-xxl {
    padding: 14px;
    font-size: 15px;
    line-height: 1;
}


.white {
    color: #ffffff !important;
}

.dark {
    color: #112328 !important;
}

.success {
    color: #3dae84 !important;
}

.purple {
    color: #646AA7 !important;
}

.primary {
    color: #5E97D0 !important;
}

.danger {
    color: #da440f !important;
}

.info {
    color: #003dff !important;
}

.warning {
    color: #FFAC5B !important;
}

.grey {
    color: #999EAA !important;
}

.Lightgrey {
    color: #cfd3da !important;
}

.turquoise {
    color: #3477F7 !important;
}

.paleTurquoise {
    color: #1eb9b2 !important;
}

.paleGreen {
    color: #76D8D3;
}

.bgWhite {
    background: #ffffff;
}

.bgDark {
    background: #e3e6e3 !important;
}

.btn-success i.active {
    color: #FFFFFF !important;
}

.btn-primary, .btn-primary i {
    color: #2a6ca7;
    border: solid 1px #2a6ca7;
}

.btn-success, .btn-success i {
    color: #27AE60;
    border: solid 1px #27AE60;
}

.btn-success.active {
    color: #FFFFFF !important;
    border: solid 1px #27AE60;
    background: #27AE60;
}

.btn-archiveInactive , .btn-archiveInactive  i {
    color: #b393bb;
    border: solid 1px #b393bb;
}

.btn-info, .btn-info i {
    color: #686ba3 !important;
    border: solid 1px #b8bce1;
}

.btn-warning, .btn-warning i {
    color: #fb922f;
    border: solid 1px #fb922f;
}


.btn-danger, .btn-danger i {
    color: #FD95A2;
    border: solid 1px #FD95A2;
}

.btn-archive , .btn-archive  i {
    color: #b655cd;
    border: solid 1px #b655cd;
}

.btn-lightGreen, .btn-lightGreen i {
    color: #95ec64;
    border: solid 1px #95ec64;
}

.btn-danger.active {
    color: #FFFFFF !important;
    border: solid 1px #FD95A2;
    background: #FD95A2;
}

.btn-danger.active {
    color: #FFFFFF !important;
    border: solid 1px #FD95A2;
    background: #FD95A2;
}

.btn-danger i.active {
   color: #FFFFFF !important;
}

.btn-lightBlue, .btn-lightBlue i {
    color: #64bdec;
    border: solid 1px #64bdec;
}

.btn-purple, .btn-purple i {
    color: #646aa7;
    border: solid 1px #b8bce1;
}

.btn-white, .btn-white i {
    color: #f3f5ff;
    border: solid 1px #f3f5ff;
}

.btn-queue, .btn-queue i {
    color: #029BE5;
    border: solid 1px #029BE5;
}

.btn-purple,.btn-purple i {
    color: #646aa7;
    border: solid 1px #646aa7;
}

.btn-warning,.btn-warning i {
    color: #fb922f;
    border: solid 1px #fb922f;
}

.btn-black {
    color: #ffffff;
    background: #000000;
    border: 2px solid black;
}

.grey-text {
    color: #d0d4de;
}

.btn-grey, .btn-grey i {
    color: #d0d4de;
    border: solid 1px #d0d4de;
}

.btn-disable,.btn-disable i {
    background: #ced2d8;
}

.btn-white,.btn-white i {
    color: #b2b4d4;
    background: #ffffff;
    border: solid 1px #b2b4d4;
}

.btn-blackBorder {
    color: #000000;
    border: 2px solid #000000 !important;
}

/*.btn-black, .btn-black i {
    color: #000000;
    background: #ffffff;
    border: solid 1px #000000;
}*/

.btn-pink,.btn-pink i {
    color: #ef3137;
    border: solid 1px #ef3137;
}

.bg-blue {
    background: #3478F7 !important;
    color: #FFFFFF !important;
}

.bg-level0 {
    background-color: #ffffff !important
}

.bg-level1 {
    background-color: #f7f7fb !important
}

.bg-level2 {
    background-color: #e6e6fd !important
}

.bg-level3 {
    background-color: #bdd0f4 !important;
}

.bg-level4 {
    background-color: #59009c !important;
}

.bg-level5 {
    background-color: #3478f7 !important
}

.bg-level6 {
    background-color: #da440f !important
}

.bg-level7 {
    background-color: #27ae60 !important;
}

.bg-level8 {
    background-color: #E7EBFB !important;
}

.bg-level9 {
    background-color: #f5c721 !important;
}

.bg-level10 {
    background-color: #ffb295 !important
}
.bg-level11 {
    background-color: #F57C02 !important
}

.bg-level12 {
    background-color: #f7e6e6  !important
} 

.bg-level13 {
    background-color: #e1faf5 !important
} 

.bg-blue>span {
    color: #FFFFFF !important;
}

.bg-green {
    background: #c0e9da !important;
    color: #618ceb !important;
}

.btn-info,.btn-info i {
    color: #686ba3;
    border: solid 1px #c8d3ff;
}
 

.btn-lightBlue, .btn-lightBlue i {
    color: #64bdec;
    border: solid 1px #64bdec;
}

.btn-archive, .btn-archive i {
    color: #b655cd;
    border: solid 1px #b655cd;
}
.btn-innactive,.btn-innactive i,.btn-innactive span {
    color: #ffffff;
    background: #9fa7f3;
}

.backgroundNone {
    background: none !important;
}

.p8 {
    padding: 8px;
}

[class*="iconButton"] {
    border: none;
    padding: 4px;
    float: left;
    /*
    margin: 0 5px;
    border-radius: 5px;*/
    color: #fff;
}

[class*="iconButton"] i {
    color: #fff;
    font-size: 18px;
}

.iconButtonSuccess {
    background-color: #1F947C;
}

.iconButtonDanger {
    background-color: #da440f;
}

.rc-tooltip-inner {
    padding: 0px !important;
    border: none !important;
    background-color: transparent !important;
}

.rc-tooltip {
    opacity: 1 !important;
    background-color: transparent !important;
    /*border-radius: 10px;*/
}

.rc-tooltip-content {
    /*border-radius: 10px;
    border: solid 1px #1F947C;*/
    opacity: 1 !important;
    background: transparent;
    overflow: hidden;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    border-left-color: #495298 !important;
    border-width: 8px 0 8px 10px !important;
    right: -8px !important;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: #1F947C !important;
}

.btn-search {
    color: #ffffff;
    background: linear-gradient(180deg, rgb(21 52 108) 0%, rgba(17, 35, 40, 1) 100%);
}

/*End button*/

/*Start topFrame*/
.leftFrame {
    border-left: solid 2px #4595cb;
    padding: 4px;
}

.topFrame {
    border-top: solid 2px #4595cb;
    padding: 6px;
    background: #ffffff;
}

.topBorder {
    border-color: #529bce;
}

.margin-small {
    margin: 4px !important;
}

.creatorImgContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.topFrame.topFrame_paleBlue {
    border-color: #7cc4e7;
}

.topFrame.topFrame_darkBlue {
    border-color: #4d54bc;
}

.topFrame.topFrame_purple {
    border-color: #a77be7;
}

.topFrame.topFrame_turquoise {
    border-color: #6ed2cd;
}

.topFrame.topFrame_orange {
    border-color: #f4aea4;
}

.topFrame.topFrame_brown {
    border-color: #bf9696;
}

.topFrame.topFrame_green {
    border-color: #3dae84;
}


.topFrameFillYellow {
    border-color: #ffe800 !important; 
    background-color: #feffe0 !important;
}


.topFrameFillGreen {
    border-color: #36e502 !important; 
    background-color: #ebf9e7 !important;
}


.topFrameFillMagenta {
    border-color: #e701e3 !important; 
    background-color: #fbecfb !important;
}
/*
thead {
    background: #fafbfd;
    color: #646aa7;
    font-weight: normal;
    font-family: 'Inter-SemiBold';
}
*/
table td,
table th {
    border: 1px solid #ececec;
    padding: 4px;
}

thead th,
table td {
    /* padding: 10px;*/
}

table {
    width: 100%;
    border: 1px solid #f4f4f4;
    cursor: pointer;
    border-collapse: collapse;
    /*  overflow: hidden;
  
    border: 1px solid #f4f4f4;
   box-shadow: 0px 0px 0px black, 0 0 3px lightgrey, 0 0 0px black;  
   */
}

.table tr.info:hover,
.table td.info:hover,
.table th.info:hover,
.table tr.info:hover td,
.table tr.info:hover th {
    background: #B9E3F3;
}

.table tr.info,
.table td.info,
.table th.info {
    background: #B9E3F3;
}

.table tr.danger,
.table td.danger,
.table th.danger {
    background-color: #F1D5D5;
}

.danger {
    color: #da440f !important;
}

.info {
    color: #008aff !important;
}

.table tr:hover {
    background-color: #f5f9ff !important;
}

.table.tablePresentation tr th {
    background: #626fbc;
    color: white;
    font-weight: normal;
    padding: 10px;
}

::placeholder {
    color: #d8dae3;
}

.zIndexUp {
    z-index: 1;
}

.zIndexUp1 {
    z-index: 99999999999999;
}

.zIndexDown {
    z-index: -1;
}

/*End Table*/

/*Start Affiliate box*/
.affiliateSummaryBoxContent {
    background-image: url(../../../assets/images/logoTransparent.png);
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: -25% 75%;
    height: 200px;
    opacity: 1;
    position: absolute;
}

.affiliateSummaryBox {
    background: linear-gradient(355deg, #1F947C 0%, #1F947C 100%);
    min-height: 200px;
}

/*End  Affiliate box*/

/*Start Loader*/
.loader {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    /* -webkit-transform: translateY(-50%) translateX(-50%);*/
    z-index: 99999;
}

.loader>div {
    height: 100%;
    width: 10px;
    display: inline-block;
    margin-left: 2px;
    -webkit-animation: delay 0.8s infinite ease-in-out;
    animation: delay 0.8s infinite ease-in-out;
}

.loader>.bar1 {
    background-color: #754fa0;
}

.loader>.bar2 {
    background-color: #09b7bf;
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.loader>.bar3 {
    background-color: #90d36b;
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.loader>.bar4 {
    background-color: #f2d40d;
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.loader>.bar5 {
    background-color: #fcb12b;
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.loader>.bar6 {
    background-color: #ed1b72;
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

@-webkit-keyframes delay {

    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.05)
    }

    20% {
        -webkit-transform: scaleY(1.0)
    }
}

@keyframes delay {

    0%,
    40%,
    100% {
        transform: scaleY(0.05);
        -webkit-transform: scaleY(0.05);
    }

    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}


#block-ui-message-container {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    text-align: center;
    z-index: 10001;
    width: 100%;
    height: 100vw;
    opacity: 0.5;
    /*End Loader*/

}

.tooltip-cotainer {
    position: relative;
    display: inline-block;
}

/*
  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateY(-180%);
    background-color: #000;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 0.8;
    pointer-events:     yes;
  }*/

.tooltip {
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 8px;
    font-size: 14px;
    opacity: 1;
    pointer-events: none;
    z-index: 999999999;
}

.tooltip::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
}

.btn-queue,
.btn-queue i {
    color: #029BE5;
    border: solid 1px #029BE5;
}

.btn-queueBg,
.btn-queueBg i {
    color: #FFFFFF;
    border: solid 1px #029BE5;
    background: #029BE5;
}

/*.box-header {
    cursor: default;
    width: 100%;
    float: left;
    background: #E7EBFB;
    padding: 8px 16px;
    /*border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff;
    border-bottom: solid 4px #dde0f5;
}*/

.box-header-main {
    cursor: default;
    width: 100%;
    float: left;
    padding: 4px 10px;/*
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-radius: 0px !important;*/
    background: #3478F7 !important;
    color: #FFFFFF !important;
}

.box-dash.box-primary .box-header,
.box-dash.box-info .box-header,
.box-dash.box-warning .box-header,
.box-dash.box-danger .box-header,
.box-dash.box-success .box-header {
    color: #ffffff;
}

.box-dash.box-primary .box-header {
    background-color: #5E97D0;
}

.box-primary.active {
    background: #95bbff;
}

.iconLogo {
    padding: 14px;
    /*border-radius: 14px;*/
}

/*.iconLogo {
    font-size: 19px;
    background: #f7f8ff;
    float: none;
    display: block;
    padding: 19px;
}*/

/*.box {
    float: left;
    border: solid 1px #e7ebfb;
    width: 100%;
    float: left;
    background: #ffffff;
    padding: 0px;
    border-radius: 8px;
}*/

.iconLogo .box.active {
    background: #3C88F7;
    color: #fff;
}

.iconLogo span.active {
    color: #3C88F7;
}

.iconLogo span {
    color: #606785;
    width: 63px;
    float: left;
    text-align: center;
    height: 40px;
}

.label-entries.combinedBtn input {
    width: calc(100% - 103px);
}

table tr th {
    padding: 8px 5px;
    background: #E7EBFB;
    /*color: #646aa7;
    font-weight: bold;
    text-transform: capitalize;
    line-height: 18px;
    white-space: nowrap;*/
}

body.metric-circle {
    width: 10px;
    height: 10px;
    display: inline-flex;
    display: -moz-inline-flex;
    display: -webkit-inline-flex;
}

body .metric-circle.processed {
    background: #d6a45e;
}

body .metric-circle {
    width: 10px;
    height: 10px;
    display: inline-flex;
    display: -moz-inline-flex;
    display: -webkit-inline-flex;
}

body .metric-circle.bounce {
    background: #D85C5C;
}

body .metric-circle.deferred {
    background: #980A0B;
}

body .metric-circle.delivered {
    background: #299A74;
}

body .metric-circle.dropped {
    background: #7B1FA2;
}

body .metric-circle.processed {
    background: #d6a45e;
}

body .metric-circle.open {
    background: #59c1ca;
}

.btn-secondary.is-active,
.btn-secondary:active {
    box-shadow: 0 1px 4px 0 rgba(26, 130, 226, .2);
}

.btn-dropdown {
    padding-right: 25px;
    padding-left: 25px;
}

.drop-menu {
    /*box-shadow: 0 2px 6px 0 rgba(41, 70, 97, .2);
    position: absolute;
    opacity: 0;
    min-width: 180px;
    visibility: hidden;
    padding: 10px 0;
    margin: 1px -1px;
    left: 0;
    top: 100px !important;
    background: #fbfbfc;
    display: inline-block;
    border: 1px solid #e9ecef;
    border-radius: 2px;
    */
    top: 17%;
    background: #fbfbfc;
    border: 1px solid #e9ecef;
    min-width: 180px;
    position: absolute;
    right: 0;
    padding: 10px;
    cursor: default;
    transition: top .3s, opacity .3s;
}

.dropdown-link {
    display: block;
    padding: 10px 18px 7px;
    font-size: 13px;
    line-height: 18px;
    color: #546b81;
    text-align: left;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
}

.label-warning {
    color: #ffffff;
    background: #FFAC5B;
    border: none;
    font-weight: bold;
    border-color: #64ADE4;
    float: left;
    margin: 0 2px;
    padding: 5px;
}

.label-success {
    color: #ffffff;
    background: #5E97D0;
    border: none;
    font-weight: bold;
    border-color: #64ADE4;
    float: left;
    margin: 0 2px;
    padding: 5px;
}

.label-danger {
    color: #ffffff;
    background: #E2A0A0;
    border: none;
    font-weight: bold;
    border-color: #64ADE4;
    float: left;
    margin: 0 2px;
    padding: 5px;
}

.label-archive {
    color: #ffffff;
    background: #b655cd;
    border: none;
    font-weight: bold;
    border-color: #64ADE4;
    float: left;
    margin: 0 2px;
    padding: 5px;
}

.label-archiveInactive {
    color: #ffffff;
    background: #b393bb;
    border: none;
    font-weight: bold;
    border-color: #64ADE4;
    float: left;
    margin: 0 2px;
    padding: 5px;
}


.dockingSlider {
    height: 86%;
    position: absolute;
    z-index: 3;
    top: 5%;
    left: 0;
    background-color: #ffffff;
    transition: 0.5s;
    box-shadow: inset 0px 0px 10px 0px #d2d7ff;
}

.wizard-container {
    display: flex;
    /*height: 100vh;*/
}

.wizard-content {
    flex-grow: 1; /* Takes the remaining space */
    padding: 20px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.wizardForm td input::placeholder, .wizardForm td textarea::placeholder {
    font-weight: normal;
    font-size: 0.8vw;
}

.wizardForm td input, .wizardForm td textarea {
    border: none;
    padding: 5px 10px;
    font-size: 0.8vw;
    line-height: 1vw;
    background: #ffff;
}

.wizardForm td input::placeholder, .wizardForm td textarea::placeholder {
    font-weight: normal;
    font-size: 0.8vw;
}

.wizard .selected i {
    color: #626fbc;
    font-size: 26px;
    margin: 0px 0 0 -13px;
}

.wizardForm .tabIndex {
    padding: 4px 0 0 5px !important;
    position: absolute !important;
    margin: -2px 0 0 -12px;
    font-size: 12px;
    width: 16px;
    text-align: center;
}

.wizardForm .selected .tabIndex {
    margin: 1px 0 0 -11px;
}

.wizard .selected span, .wizard .selected a {
    color: #ffffff;
    font-weight: bold;
    padding: 7px 0 0 5px;
}

.wizard i {
    font-size: 18px;
    color: #ffffff;
    margin: 0px 0 0 -10px;
}

.wizard td {
    vertical-align: top;
    padding: 0 0 30px;
    border: none;
}

.wizard td span, .wizard td a {
    vertical-align: top;
    border: none;
    float: left;
    color: #7f87bb;
    font-size: 12px;
    padding: 2px 0 0 5px;
}

.zFront {
    z-index: 99999 !important;
}


.regions-controls .exisitingRegionsList li {
    border: 1px #DFDFDF solid;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(223, 223, 223);
    border-right-color: rgb(223, 223, 223);
    border-bottom-color: rgb(223, 223, 223);
    border-left-color: rgb(223, 223, 223);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    background: #ffffff;
    padding: 3px 8px;
    cursor: pointer;
    margin-left: -40px;
}

.regions-controls .exisitingRegionsList {
    position: absolute;
    margin: 0;
    list-style-type: none;
    top: 140px;
    left: 10px;
    max-height: 209px;
    overflow-y: auto;
    width: 40%;
    z-index: 999;
}


/*Start Tags*/
.tagging {
    float: left;
    margin: 2px;
}

.tagging span {
    float: left;
    padding: 4px 4px;
    background: #2d3a8f;
    color: #ffffff;
}

.tagging span i {
    font-size: 15px;
    color: #ffffff;
}

.tagging-container {
    white-space: nowrap;
    /*border: solid 1px #2d3a8f;*/
    float: left;
    margin: 2px 0px 0px 2px;
}

/*End Tags*/



/** Start ng-dialog **/
@-webkit-keyframes ngdialog-flyin {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes ngdialog-flyin {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
        transform: translateY(-40px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes ngdialog-flyout {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }
}

@keyframes ngdialog-flyout {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
        transform: translateY(-40px);
    }
}

.animate {
    transition-delay: 2s;
    transition-timing-function: linear;
}

.spinnerIcon360 i {
    position: relative;
    -ms-animation: uil-rolling-anim 1s linear infinite;
    -moz-animation: uil-rolling-anim 1s linear infinite;
    -webkit-animation: uil-rolling-anim 1s linear infinite;
    -o-animation: uil-rolling-anim 1s linear infinite;
    animation: uil-rolling-anim 1s linear infinite;
}

.ngdialog.ngdialog-theme-wide .ngdialog-content {
    width: 90% !important;
}

.ngdialog.ngdialog-theme-default {
    padding-bottom: 160px;
    padding-top: 160px;
}

.ngdialog.ngdialog-theme-default.ngdialog-closing .ngdialog-content {
    -webkit-animation: ngdialog-flyout .5s;
    animation: ngdialog-flyout .5s;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
    -webkit-animation: ngdialog-flyin .5s;
    animation: ngdialog-flyin .5s;
    background: transparent;
    margin: 0 auto;
    position: relative;
}

.ngdialog.ngdialog-theme-default .ngdialog-close {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: 20px;
}

.ngdialog,
.ngdialog *,
.ngdialog *:before,
.ngdialog *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.ngdialog {
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.ngdialog-overlay {
    position: fixed;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation: ngdialog-fadein 0.5s;
    animation: ngdialog-fadein 0.5s;
}

.ngdialog.ngdialog-closing .ngdialog-overlay {
    -webkit-animation: ngdialog-fadeout 0.5s;
    animation: ngdialog-fadeout 0.5s;
}

.ngdialog-content {
    background: white;
    -webkit-animation: ngdialog-fadein 0.5s;
    animation: ngdialog-fadein 0.5s;
}

.ngdialog.ngdialog-closing .ngdialog-content {
    -webkit-animation: ngdialog-fadeout 0.5s;
    animation: ngdialog-fadeout 0.5s;
}

.ngdialog-close:before {
    font-family: 'Helvetica', Arial, sans-serif;
    content: '\00D7';
    cursor: pointer;
}

body.ngdialog-open,
.overflowHidden {
    overflow: hidden;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
    padding-top: 32px;
    margin-top: 50px;
    width: 85%;
    /*  overflow: auto;*/
}

.ngdialog.ngdialog-theme-default.ngdialog-delete .ngdialog-content {
    width: 50%;
    /*  overflow: auto;*/
}

.ngdialog.ngdialog-theme-default.ngdialog-delete .ngdialog-content .box-content {
    min-height: 150px;
}

.ngdialog-content>div {
    float: left;
    border: solid 2px #6a96bf;
    background: #ffffff;
    width: 100%;

    /*
     background: #2d3a8f !important;       
    box-shadow: 2px 1px 8px #486a9c;
        padding: 5px;
 border-radius: 5px;
    background: #2d3a8f;

    background: #e0e3f1;
    */
}

.wizardNavigation {
    background: #2d3a8f !important;
    box-shadow: none;
    border-radius: 9px;
}

.ngdialog-content>.ngdialog-close {
    border: none;
    padding: 0px;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:before {
    background: #2d3a8f;
    color: #fff;
    content: '\00D7';
    font-size: 26px;
    font-weight: 400;
    height: 26px;
    line-height: 26px;
    position: absolute;
    right: 3px;
    text-align: center;
    top: 3px;
    width: 26px;
}

.ngdialog.ngdialog-slider .ngdialog-content {
    background: none;
    padding: 0px;
    margin: 0px;
    width: 100%;
}

.progress {
    height: 30px;
    margin-bottom: 20px;
    overflow: hidden;
    background-color: #f6f0f0;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.ngdialog.ngdialog-slider .ngdialog-content>div {
    border: none;
}

.ngdialog.ngdialog-slider .ngdialog-close {
    display: none;
}


/** End ng-dialog **/

[class*="icon-"] .path2 {
    position: absolute;
}

.mobile .shadowCoverPicture {
    width: 170px;
    height: 220px;
}

.mobile .shadowCoverPicture .coverPicture img {
    height: 173px;
}


.mobile .shadowCoverPicture label {
    font-size: 10px;
}


.mobile .shadowCoverPicture .pLarge {
    padding: 5px !important;
}

.mobile .loginWelcome .verticalCentering {
    top: 0px !important;
}

@media only screen and (max-width: 1000px) {
    .loginWelcome .verticalCentering {
        top: 0px !important;
    }

    .loginForm {
        padding: 40px;
        top: 90px;
        background: rgb(255 255 255 / 20%);
    }
}

.colorWhite {
    color: #fff !important;
}

/*Start Tri State Switch*/
.triSelectContainer {
    width: fit-content;
    background: #6672c1;
    /*border: solid 1px #bdd0f4;*/
    margin: 20px;
    border-radius: 30px;
    height: 28px;
}

.triSelect {
    display: inline-block;
    margin: 0px;
    padding: 2px;
}

/*  input[type="radio"] {
      display: none;
  }*/

.triSelect .checkmark {
    width: 22px;
    height: 22px;
}

.checkmark {
    padding: 0px;
    margin: 0px;
}


.checkmark i {
    font-size: 24px;
    color: #fff;
}

.yes:checked+.checkmark {
    background-color: #27AE60;
    border-radius: 30px;
    border: 1px solid transparent;

    -moz-animation-duration: 0.4s;
    -moz-animation-name: slidein;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-name: slidein;
    animation-duration: 0.4s;
    animation-name: slidein;
    -webkit-transform: translateZ(0);
}

.neutral:checked+.checkmark {
    background-color: #ccc;
    border: 1px solid black;
    border-radius: 30px;

    background: rgb(238, 238, 238);
}

.no:checked+.checkmark {
    background-color: #da440f;
    border-radius: 30px;

    -moz-animation-duration: 0.4s;
    -moz-animation-name: slideno;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-name: slideno;
    animation-duration: 0.4s;
    animation-name: slideno;
}

.ion-checkmark-round,
.ion-close-round {
    margin-left: 10px;
    margin-top: 10px;
    color: #fff;
    line-height: 30px;
}

.ion-record {
    margin-left: 8px;

    color: #fff;
    line-height: 30px;
}





/**Date picker**/

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #ffffff;
    color: #646aa7;
    border: 1px solid #e7ebfb;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    width: 100% !important;
    margin: 100px;
    top: -100PX;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    margin-top: 0;
    color: #646aa7;
    font-weight: bold;
    font-size: 0.944rem;
    display: none;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, 
.react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, 
.react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
    /*display: inline-block;*/
    width: 45% !important;
    left: 0px !important;
    margin: 0 10px;
    position: relative;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
    width: auto;
    margin-left: 0;
    border: solid #DEDEDE 1px;
    border-radius: 5px;
    color: #47529d;
}

.react-datepicker__month-container {
    float: left;
    width: auto !important;
    width: 100% !important;    
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0; 
    border-top-left-radius: 0.3rem; 
    border-bottom : none;
    background: #E7EBFB;
    color: #646aa7;
}

.react-datepicker__month { 
    text-align: center;
    width: 85% !important;
    padding: 5px;
}

.react-datepicker__week {
    white-space: nowrap;
    border: 1px solid #ececec00;
    text-align: center !important;
    width: 100% !important;
}

.react-datepicker__day-names {
    margin-bottom: -2% !important;
    width: 85% !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    display: inline-block;
    min-width: 15% !important;
    line-height: 1.7rem;
    text-align: center;
    margin: 0px 5px 0px 0px;
    padding: 5px;
    color: #6472a7;
    font-weight: bold;
    border-radius: 0.3rem;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
    cursor: pointer;
    border: solid 1px #0e0f0f !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, .react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    border: solid 1px #3dae84 !important;
    color: #41445c !important; 
    min-width: 15% !important;
    background-color: #3dae84 !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: none;
    color: #41445c !important;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, 
.react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
    background-color: #909792;
}

.react-datepicker-ignore-onclickoutside {
    background-color: #f5f5f5;
    border-radius: 5px;
    pointer-events: none;
}

.react-datepicker__input-container input {
    background-color: #f5f5f5!important;
    border-radius: 5px!important;
    cursor: pointer;
    pointer-events: auto; 
}

.react-datepicker__input-container input[readonly] {
    background-color: #e0e0e0; /* Slightly different background */
    cursor: not-allowed; /* Indicate that typing is not allowed */
}

.react-datepicker__navigation-icon--next {
    left: -2px;
    display: none;
    
}

.react-datepicker__navigation-icon--previous {
    right: -2px;
    display: none;
}


.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: none !important; 
    /* cursor: pointer; */
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
    background-color: #f0f0f0;
    background: #E7EBFB;
    color: #646aa7;
}


.ql-toolbar {
    display: inline !important; 
    background: none !important;
}
.hover-box:hover>.ql-toolbar {
     display:  inline !important; 
}

.ql-toolbar.ql-snow .ql-formats {
    margin-right: 3px !important;  
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color:none !important;
}

.ql-formats button {  
    margin: 3px !important; 
    border :solid 1px #cdc2c2 !important;
    border-radius: 4px;
}

.ql-picker {  
    margin: 3px !important; 
    border :solid 1px #cdc2c2 !important;
    border-radius: 4px;
}


.ql-toolbar.ql-snow {
    border:none !important; 
    /* box-sizing: border-box; */
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
}

/**Date picker**/
@keyframes slidein {
    from {
        transform: translate(50px, 0);
    }

    to {
        transform: translate(0px, 0px);
    }
}


@keyframes slideno {
    from {
        transform: translate(-50px, 0);
    }

    to {
        transform: translate(0px, 0px);
    }
}

@keyframes returnLeft {
    from {
        transform: translate(-50px, 0);
    }

    to {
        transform: translate(0px, 0);
    }
}

@keyframes returnRight {
    from {
        transform: translate(50px, 0);
    }

    to {
        transform: translate(0px, 0);
    }
}

/*End Tri State Switch*/