*,
*:before,
*:after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
}

[class*="ws-"], [class*="w-"] {
    float: left;
    width : 100%;
}

.crow {
  text-align: center;
}
.crow:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.crow > * {
  vertical-align: middle;
  display: inline-block;
  /*font-size: medium;*/
  /* IE */
  /*font-size: initial;*/
  text-align: left;
  margin: 0;
  padding: 0;
}
.crow.fly > *:first-child:nth-last-child(12),
.crow.fly > *:first-child:nth-last-child(12) ~ * {
  width: 8.333333333333334%;
}
.crow.fly > *:first-child:nth-last-child(11),
.crow.fly > *:first-child:nth-last-child(11) ~ * {
  width: 9.090909090909092%;
}
.crow.fly > *:first-child:nth-last-child(10),
.crow.fly > *:first-child:nth-last-child(10) ~ * {
  width: 10%;
}
.crow.fly > *:first-child:nth-last-child(9),
.crow.fly > *:first-child:nth-last-child(9) ~ * {
  width: 11.11111111111111%;
}
.crow.fly > *:first-child:nth-last-child(8),
.crow.fly > *:first-child:nth-last-child(8) ~ * {
  width: 12.5%;
}
.crow.fly > *:first-child:nth-last-child(7),
.crow.fly > *:first-child:nth-last-child(7) ~ * {
  width: 14.285714285714286%;
}
.crow.fly > *:first-child:nth-last-child(6),
.crow.fly > *:first-child:nth-last-child(6) ~ * {
  width: 16.666666666666668%;
}
.crow.fly > *:first-child:nth-last-child(5),
.crow.fly > *:first-child:nth-last-child(5) ~ * {
  width: 20%;
}
.crow.fly > *:first-child:nth-last-child(4),
.crow.fly > *:first-child:nth-last-child(4) ~ * {
  width: 25%;
}
.crow.fly > *:first-child:nth-last-child(3),
.crow.fly > *:first-child:nth-last-child(3) ~ * {
  width: 33.333333333333336%;
}
.crow.fly > *:first-child:nth-last-child(2),
.crow.fly > *:first-child:nth-last-child(2) ~ * {
  width: 50%;
}
.crow.fly > *:first-child:nth-last-child(1),
.crow.fly > *:first-child:nth-last-child(1) ~ * {
  width: 100%;
}
.crow.up > * {
  vertical-align: top;
}
.crow.down > * {
  vertical-align: bottom;
}
.crow > *.up {
  vertical-align: top;
}
.crow > *.down {
  vertical-align: bottom;
}
.crow .w-100 {
  width: 100%;
}
.crow .w-99 {
  width: 99%;
}
.crow .w-98 {
  width: 98%;
}
.crow .w-97 {
  width: 97%;
}
.crow .w-96 {
  width: 96%;
}
.crow .w-95 {
  width: 95%;
}
.crow .w-94 {
  width: 94%;
}
.crow .w-93 {
  width: 93%;
}
.crow .w-92 {
  width: 92%;
}
.crow .w-91 {
  width: 91%;
}
.crow .w-90 {
  width: 90%;
}
.crow .w-89 {
  width: 89%;
}
.crow .w-88 {
  width: 88%;
}
.crow .w-87 {
  width: 87%;
}
.crow .w-86 {
  width: 86%;
}
.crow .w-85 {
  width: 85%;
}
.crow .w-84 {
  width: 84%;
}
.crow .w-83 {
  width: 83%;
}
.crow .w-82 {
  width: 82%;
}
.crow .w-81 {
  width: 81%;
}
.crow .w-80 {
  width: 80%;
}
.crow .w-79 {
  width: 79%;
}
.crow .w-78 {
  width: 78%;
}
.crow .w-77 {
  width: 77%;
}
.crow .w-76 {
  width: 76%;
}
.crow .w-75 {
  width: 75%;
}
.crow .w-74 {
  width: 74%;
}
.crow .w-73 {
  width: 73%;
}
.crow .w-72 {
  width: 72%;
}
.crow .w-71 {
  width: 71%;
}
.crow .w-70 {
  width: 70%;
}
.crow .w-69 {
  width: 69%;
}
.crow .w-68 {
  width: 68%;
}
.crow .w-67 {
  width: 67%;
}
.crow .w-66 {
  width: 66%;
}
.crow .w-65 {
  width: 65%;
}
.crow .w-64 {
  width: 64%;
}
.crow .w-63 {
  width: 63%;
}
.crow .w-62 {
  width: 62%;
}
.crow .w-61 {
  width: 61%;
}
.crow .w-60 {
  width: 60%;
}
.crow .w-59 {
  width: 59%;
}
.crow .w-58 {
  width: 58%;
}
.crow .w-57 {
  width: 57%;
}
.crow .w-56 {
  width: 56%;
}
.crow .w-55 {
  width: 55%;
}
.crow .w-54 {
  width: 54%;
}
.crow .w-53 {
  width: 53%;
}
.crow .w-52 {
  width: 52%;
}
.crow .w-51 {
  width: 51%;
}
.crow .w-50 {
  width: 50%;
}
.crow .w-49 {
  width: 49%;
}
.crow .w-48 {
  width: 48%;
}
.crow .w-47 {
  width: 47%;
}
.crow .w-46 {
  width: 46%;
}
.crow .w-45 {
  width: 45%;
}
.crow .w-44 {
  width: 44%;
}
.crow .w-43 {
  width: 43%;
}
.crow .w-42 {
  width: 42%;
}
.crow .w-41 {
  width: 41%;
}
.crow .w-40 {
  width: 40%;
}
.crow .w-39 {
  width: 39%;
}
.crow .w-38 {
  width: 38%;
}
.crow .w-37 {
  width: 37%;
}
.crow .w-36 {
  width: 36%;
}
.crow .w-35 {
  width: 35%;
}
.crow .w-34 {
  width: 34%;
}
.crow .w-33 {
  width: 33%;
}
.crow .w-32 {
  width: 32%;
}
.crow .w-31 {
  width: 31%;
}
.crow .w-30 {
  width: 30%;
}
.crow .w-29 {
  width: 29%;
}
.crow .w-28 {
  width: 28%;
}
.crow .w-27 {
  width: 27%;
}
.crow .w-26 {
  width: 26%;
}
.crow .w-25 {
  width: 25%;
}
.crow .w-24 {
  width: 24%;
}
.crow .w-23 {
  width: 23%;
}
.crow .w-22 {
  width: 22%;
}
.crow .w-21 {
  width: 21%;
}
.crow .w-20 {
  width: 20%;
}
.crow .w-19 {
  width: 19%;
}
.crow .w-18 {
  width: 18%;
}
.crow .w-17 {
  width: 17%;
}
.crow .w-16 {
  width: 16%;
}
.crow .w-15 {
  width: 15%;
}
.crow .w-14 {
  width: 14%;
}
.crow .w-13 {
  width: 13%;
}
.crow .w-12 {
  width: 12%;
}
.crow .w-11 {
  width: 11%;
}
.crow .w-10 {
  width: 10%;
}
.crow .w-9 {
  width: 9%;
}
.crow .w-8 {
  width: 8%;
}
.crow .w-7 {
  width: 7%;
}
.crow .w-6 {
  width: 6%;
}
.crow .w-5 {
  width: 5%;
}
.crow .w-4 {
  width: 4%;
}
.crow .w-3 {
  width: 3%;
}
.crow .w-2 {
  width: 2%;
}
.crow .w-1 {
  width: 1%;
}
.crow .w-0 {
  width: 0px;
}
.crow .ws-12 {
  width: 100%;
}
.crow .ws-11 {
  width: 91.66666666666667%;
}
.crow .ws-10 {
  width: 83.33333333333333%;
}
.crow .ws-9 {
  width: 75%;
}
.crow .ws-8 {
  width: 66.66666666666667%;
}
.crow .ws-7 {
  width: 58.333333333333336%;
}
.crow .ws-6 {
  width: 50%;
}
.crow .ws-5 {
  width: 41.666666666666664%;
}
.crow .ws-4 {
  width: 33.333333333333336%;
}
.crow .ws-3 {
  width: 25%;
}
.crow .ws-2 {
  width: 16.666666666666668%;
}
.crow .ws-1 {
  width: 8.333333333333334%;
}
.crow.gutter {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: -15px;
  margin-left: -15px;
}
.crow.gutter > * {
  padding-right: 15px;
  padding-left: 15px;
}
.nest {
  width: 960px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/*
*/
.crow .w-14-2 {
    width: 14.2%;
}


/*Crow Responsive*/

@media only screen and (max-width: 1000px) {

    .crow .ws-3, .ws-2, .crow .ws-4, .crow .ws-5, .crow .ws-6, .crow .ws-7, .crow .ws-8, .crow .ws-9, .crow .ws-10, .crow .ws-11, .crow .ws-12 {
        width: 100% !important;
    }
}
